@import "~scss/imports";
$placeholder-height: 3rem;
$placeholder-pad: $placeholder-height/2;
.EditForm {
  input,
  textarea {
    @extend %font--input;
    padding: 1em $placeholder-pad;
    position: relative;
    display: block;
    width: 100%;

    &:focus {
      outline: 2px solid $user-color;
    }
  }

  textarea {
    resize: vertical;
    min-height: 15em;
    line-height: 1.5;
  }
}

.label-with-placeholder {
  display: block;
  position: relative;
  max-width: 50em;
  margin-top: 1rem;

  .placeholder {
    @extend %font--label;
    align-items: center;
    color: $black;
    display: inline-flex;
    padding: $placeholder-pad/2 0;
    pointer-events: none;
    z-index: 1;
  }

  textarea,
  input {
    background-color: $gray;
    border: 0;

    &:focus ~ .placeholder {
      color: rgba($black, 0.5);
    }
  }
}
