@import "~scss/imports";

.Unsaved {
  @extend %font--body;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;

  .SaveButton {
    margin-left: 2em;
  }

  .SaveButton .tab {
    flex-direction: row;
    align-items: center;
    svg {
      margin-bottom: 0;
      margin-right: 0.5em;
    }
  }
}
