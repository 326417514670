@import "~scss/imports";

.home-pad {
  padding-left: 20px;
  padding-right: 20px;

  @include breakpoint("sm") {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include breakpoint("md") {
    padding-left: 8vw;
    padding-right: 0;
  }
}

.Home-top {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-bottom: $navHeight;
  min-height: calc(100vh - #{$navHeight});

  @include breakpoint("md") {
    align-items: center;
    flex-direction: row;
  }

  .container-wrapper {
    min-height: calc(100vh - #{$navHeight});
    margin: 0 auto;

    @include breakpoint("md") {
      align-items: center;
      flex-direction: column;
      flex-direction: row;
      justify-content: space-between;

      .container {
        width: 50%;
      }
    }
  }

  .container:first-child {
    order: 2;
    width: 100%;
    margin: 0 auto;
    max-width: 500px;

    @include breakpoint("md") {
      order: 1;
    }

    @include breakpoint("lg") {
      width: 50vw;
      margin-right: 40px;
      margin-left: 0;
    }
  }

  .container--illustration {
    order: 1;
    height: 100%;
    max-width: 200px;
    margin: 0 auto;

    @include breakpoint("md") {
      order: 2;
    }

    @include breakpoint("lg") {
      max-width: 600px;
    }

    img {
      display: block;
      width: 100%;
      max-height: 100%;
      margin: 0 auto;
    }
  }

  p {
    max-width: 500px;
  }
}

.body,
.cta {
  margin-top: 2em;
}

.Home-featured {
  padding-top: 8em;
  padding-bottom: 8em;
}

.FeaturedArticles {
  margin-top: 4em;
}

:root {
  --primary-color: #251ca2;
  --black: #333;
  --size-h3: 20px;
  --article-grid-gap: 20px;
}

@media (min-width: 650px) {
  :root {
    --size-h3: 22px;
    --article-grid-gap: 30px;
  }
}

@media (min-width: 1200px) {
  :root {
    --size-h3: 28px;
    --article-grid-gap: 40px;
  }
}
.ArticleList {
  font-family: $ff-primary;
  list-style-type: none;
  display: grid;
  grid-gap: var(--article-grid-gap);
  padding: 30px 0;
  max-width: var(--max-column);
  margin: 0 auto;
}
.ArticleList-item a {
  color: inherit;
  text-decoration: none;
}
.ArticleList-item a:hover {
  color: var(--primary-color);
}

.ArticleList-item--large a {
  display: grid;
  grid-gap: var(--article-grid-gap);
}
.ArticleList-item--large .ArticleList-item-label {
  font-size: var(--size-h1);
}

.ArticleList-item-meta {
  padding-top: 0.5rem;
  border-top: 1px solid var(--primary-color);
}
.ArticleList-item-label {
  margin-top: 0.5em;
  font-size: var(--size-h3);
}
.ArticleList-item .Authors {
  font-family: $ff-secondary;
  margin-top: 1em;
}

.ArticleList-item-description {
  margin-top: 1em;
}

@media (min-width: 700px) {
  .ArticleList-item--large a {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1100px) {
  .ArticleList {
    grid-template-columns: repeat(6, 1fr);
  }
  .ArticleList-item--large {
    grid-column: span 6;
  }
  .ArticleList-item--medium {
    grid-column: span 2;
  }
  .ArticleList-item--small {
    grid-column: span 3;
  }
}
@media (min-width: 1500px) {
  .ArticleList-item--large {
    grid-column: span 6;
  }
  .ArticleList-item--medium {
    grid-column: span 2;
  }
}
