@import "~scss/imports";

#hamburger-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000;
  color: #fff;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  font-family: "basis-grotesque-medium";
  -webkit-font-smoothing: antialiased;

  z-index: 11;

  transition: transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateX(-100%);

  @include breakpoint("md") {
    max-width: 300px;
  }

  .hamburger-content-wrapper {
    padding: 30px 20px;

    display: flex;
    flex-flow: column nowrap;

    justify-content: space-between;

    overflow-y: auto;
    overflow-x: hidden;

    height: calc(100vh - #{$navHeight});

    @include breakpoint("md") {
      height: calc(100vh - #{$navHeight});
    }
  }

  .hamburger-menu-bar {
    width: 100%;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    padding: 0 15px 0 20px;

    height: $navHeight;

    @include breakpoint("md") {
      height: $navHeight;
    }

    svg {
      fill: #fff;
    }
  }

  .links-wrapper a {
    display: block;
    font-size: 1.25em;
    color: #fff;
    text-decoration: none;
    margin-top: 0px;
    line-height: 40px;
  }

  .more-info-wrapper {
    font-size: 0.9em;
    line-height: 1.45em;
  }

  .logo-wrapper {
    margin-top: 20px;
    width: 180px;
    padding: 12px 0 0;
  }

  .logo-wrapper img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }

  .logo-wrapper .hoover-logo {
    width: 60%;
    display: inline-block;
  }

  .logo-wrapper .stanford-logo {
    width: 40%;
    display: inline-block;
  }

  .social-wrapper {
    margin-top: 30px;
  }

  .social-wrapper .connect-label {
    vertical-align: top;
    color: #999999;
  }

  .social-wrapper .social-icon {
    width: 20px;
    height: 20px;
    fill: #fff;
    margin-left: 20px;
  }

  .copyright-wrapper {
    margin-top: 30px;
    color: #999999;
  }

  .additional-links-wrapper a {
    display: block;
    color: #fff;
    text-decoration: none;
  }

  .additional-links-wrapper {
    margin-top: 30px;
  }

  .additional-links-wrapper a {
    color: #999999;
  }

  a:hover {
    opacity: 0.8;
  }
}
