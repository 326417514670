.Lightswitch {
	$height: 2rem;
	$circle-height: $height*.75;
	$circle-pad: ($height - $circle-height)/2;

	border-radius: $height;
	cursor: pointer;
	height: $height;
	position: relative;
	overflow: hidden;
	width: $height*2.1;

	.background {
		background-color: #eee;
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.switch {
		width: $circle-height;
		height: $circle-height;
		margin: $circle-pad;
		border-radius: 100%;
		background-color: black;
		position: relative;
	}

	input[type="checkbox"] {
		display: none;

		&:checked ~ .switch {
			margin-left: auto;
		}
	}
}