@import "~scss/imports";

//Charts
.line-path {
  fill: none;
  stroke-width: 3;
  transition: stroke $transition-timing;

  &.comparison {
    stroke: $black;
    stroke-width: 1;
    stroke-opacity: 0.5;
    stroke-dasharray: 5;
  }
}
.x-axis {
  line,
  path {
    display: none;
  }
}

.Chart {
  > svg {
    width: 100%;
    height: 100%;
    overflow: visible;
  }
  text {
    fill: $black;
  }
}

.ChartContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .HeroChart {
    width: 100%;

    & + .HeroChart {
      margin-top: 1rem;
    }

    @include breakpoint("lg") {
      & + .HeroChart {
        margin-top: 0;
      }
      width: 30%;
    }
  }
}

.HeaderChart-wrap {
  $sizes: (
    "base": 10px,
    "xxs": 10px,
    "xs": 10px,
    "sm": 10px,
    "md": 10px,
    "lg": 18px,
    "xl": 18px,
    "xxl": 20px,
  );

  .main {
    @include breaks("margin-bottom", $sizes);
  }

  .main {
    .Chart {
      padding-left: 1em;
    }
    .ValueSet {
      margin-right: 2em;
    }
    .FigureSet--table {
      display: none;
    }
  }
}

.HeroChart {
  display: grid;
  grid-template-areas:
    "header"
    "legend"
    "chart";

  .chart-wrap {
    grid-area: chart;
    position: relative;
  }

  .chart-header {
    grid-area: header;
  }

  .Legend {
    grid-area: legend;
  }
}

.Projections {
  .HeaderChart-block-year {
    width: 100%;
    @include breakpoint("md") {
      width: auto;
    }
  }

  .HeaderChart-block-longterm {
    position: relative;
    width: 100%;
  }
}

.HeaderChart-controls {
  flex-shrink: 0;
  width: 100%;
}

.HeaderChart-label {
  margin-bottom: 0.5em;
}
.HeaderChart-unit {
  margin-top: 0.5em;
  text-align: right;
}

.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
  background-color: $pale;
  @extend %nav-pad;
}

.chart-wrap {
  width: 100%;

  .Chart {
    margin-top: auto;
    width: 100%;
  }
}

.axes {
  text,
  line {
    &.highlighted {
      opacity: 1;
    }
  }
  line {
    stroke: $black;
    opacity: 0.2;
  }

  text {
    font-family: var(--mono);
    opacity: 0.5;
  }
}

.display-year-line {
  stroke: $black;
  stroke-dasharray: 5;
}

.HeroChart {
  svg * {
    vector-effect: non-scaling-stroke;
  }

  .chart-header {
    .RadioGroup-item input[type="radio"] {
      position: absolute;
      opacity: 0;
    }

    .RadioGroup-item .RadioGroup-label {
      position: relative;
    }

    .RadioGroup-item .RadioGroup-label .text {
      display: inline-block;
      margin-left: 20px;
      user-select: none;
    }

    .RadioGroup-item .RadioGroup-label::before {
      position: absolute;
      top: -1px;
      content: "";
      display: inline-block;

      height: 14px;
      width: 14px;

      border-radius: 14px;
      border: 1px solid $black;
      background-color: $white;
    }

    .RadioGroup-item .RadioGroup-label::before {
    }

    .RadioGroup-item .RadioGroup-label::after {
      position: absolute;
      left: 6px;
      top: 5px;
      content: "";
      display: inline-block;
      height: 4px;
      width: 4px;
      border-radius: 4px;
      background-color: $white;
    }

    .RadioGroup-item input[type="radio"] + .RadioGroup-label::after {
      content: none;
    }

    .RadioGroup-item input[type="radio"]:focus + .RadioGroup-label .text {
      text-decoration: underline;
    }

    .RadioGroup-item input[type="radio"]:checked + .RadioGroup-label::after {
      content: "";
    }

    .RadioGroup-item input[type="radio"]:checked + .RadioGroup-label::before {
      background-color: $black;
    }
  }
}

// .Legend {
// 	margin-top: auto;
// }

.Legend-list {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  list-style-type: none;
  //margin-top: 15px;
}

.Legend-item {
  display: flex;
  align-items: center;
  margin-left: 0.5em;

  svg {
    margin-right: 0.25em;
  }
}

// .chart-data-select,
// .chart-unit-select {
// 	$height: 1.25em;

// 	.rrs__label {
// 		background-color: $white;
// 		border: 1px solid rgba($black,0.25);
// 		border-radius: 3px;
// 		padding: 5px;

// 		.heading {
// 			margin: 0;
// 		}
// 	}
// 	.rrs__button + .rrs__options {
// 		top: 0;
// 	}
// }

// .rrs__button:focus .rrs__label {
// 	box-shadow: 0 0 3px 3px rgba($black,.1);
// 	border-color: transparent !important;

// }
.chart-unit-selector {
  margin-top: 10px;
  padding-left: 3px;
}

.XAxis {
  position: relative;

  //temp tooltips overflowing TODO
  overflow-x: hidden;

  &.hovered {
    .XAxis-tick-wrap {
      opacity: 0;
    }
    .XAxis-hover-tick {
      opacity: 1;
    }
  }
}
.XAxis-hover-tick {
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  transition: opacity $transition-timing/2;
  opacity: 0;
}

.XAxis-tick-wrap {
  display: flex;
  justify-content: space-between;
  opacity: 1;
  transition: opacity $transition-timing;
}

.XAxis-tick:not(:first-child):not(:last-child) {
  display: none;

  @include breakpoint("md") {
    display: block;
  }
}

.hover-target {
  .hover-guideline,
  .hover-value {
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
    .hover-guideline,
    .hover-value {
      opacity: 1;
    }
  }
}

.hover-hit {
  fill: transparent;
}
.hover-guideline {
  stroke: $black;
  stroke-width: 1;
  vector-effect: non-scaling-stroke;
}

.MultiChart {
  //display: flex;
}

.MultiChart-item {
  display: flex;
  flex-direction: column;

  & + .MultiChart-item {
    margin-left: 1em;
  }
}
