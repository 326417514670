@import "~scss/imports";
$settings-grid-gap: 10px;

.GrowthSlider {
  width: 100%;
  max-width: 600px;
}
.GrowthRateSettings {
  display: inline-grid;
  grid-template-rows: auto auto;

  &.GrowthRateSettings--two {
    @include breakpoint("xs") {
      grid-template-rows: 1fr;
      grid-template-columns: auto auto;
    }
  }

  .GrowthSlider {
    align-items: flex-start;
    display: grid;

    grid-template-areas:
      "label"
      "input";

    .GrowthRateSetting-label {
      grid-area: label;
    }

    .GrowthRatePercentageInput {
      grid-area: input;
    }
  }

  .GrowthRateSelect {
    justify-content: flex-start;
    @include breakpoint("md") {
      justify-content: flex-end;
    }
  }
}

.GrowthRateSetting {
  background-color: $user-light;
  border-radius: $ui-border-radius;

  & + .GrowthRateSetting {
    margin-top: 5px;

    @include breakpoint("xs") {
      margin-top: 0;
      margin-left: 5px;
    }
  }
}

.GrowthRateSetting-label {
  display: flex;
  justify-content: space-between;
}
.GrowthRateSetting-tooltip {
  flex-shrink: 0;
  padding-left: 1em;
  @extend %faded;

  &:hover {
    opacity: 1;
  }

  &:after {
    border: 1px solid;
    border-radius: 50%;
    flex-shrink: 0;
    content: "i";
    line-height: 1;
    width: 1em;
    height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.GrowthRateSelect,
.GrowthSlider {
  padding: 10px;
  grid-row-gap: $settings-grid-gap;
}

.GrowthRateSelect .rrs__button,
.GrowthRatePercentageInput {
  min-height: 30px;
}

.growth-slider.rc-slider {
}

.GrowthRateSelect {
  $select-border-radius: 6px;
  display: grid;
  grid-template-areas:
    "label label"
    "select select"
    "plusminus input";
  grid-template-columns: 1fr auto;

  @include breakpoint("lg") {
    grid-template-areas:
      "label label label"
      "select plusminus input";
    grid-column-gap: $settings-grid-gap;
  }

  .plus-minus {
    display: flex;
    align-self: center;
    justify-self: center;
    grid-area: plusminus;
    padding: 0.5em 0;
  }
  .GrowthRateSetting-label {
    grid-area: label;
  }
  .select {
    grid-area: select;
  }
  .GrowthRatePercentageInput {
    grid-area: input;
  }

  .rrs__option {
    color: $black;
  }
  .rrs__button {
    border-color: $user-color;
    color: $black;
  }
}

.GrowthRatePercentageInput {
  $pad: 5px;
  $margin: 0.5em;
  border: 1px solid;
  display: inline-flex;
  justify-content: space-between;

  .input-wrap,
  .increment-button,
  .decrement-button,
  .button-reset {
    padding: $pad;
  }

  .increment-controls,
  .button-reset {
    margin-left: 0.5em;
  }

  .input-wrap {
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  input {
    text-align: right;
    &:focus {
      outline: 0;
    }
  }

  .increment-button {
    border-left-width: 1px;
    border-left-style: solid;
  }
  .decrement-button {
    border-right-width: 1px;
    border-right-style: solid;
  }

  .button-reset {
    border: 0;
    border-radius: 50%;
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    padding: 0;
    position: relative;

    svg {
      display: block;
      height: 60%;
      width: 60%;
      fill: white;
      position: absolute;
      top: 20%;
      left: 20%;
    }
  }
}

.increment-controls {
  display: flex;
  flex-direction: column;
}
