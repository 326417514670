.ChartButtonControls {
  --gray: #f7f7f7;

  padding: 0;
  margin: 0;
  list-style-type: none;
  border: 1px solid var(--gray);
  border-radius: 3px;
  overflow: hidden;
}

.ChartButtonControls-item {
}

.ChartButtonControls-item-button {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  text-align: left;
  cursor: pointer;

  border-left-width: 5px;
  border-left-style: solid;
  border-color: transparent;

  font-family: var(--basis);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 21px;

  padding: 1em 0;
  padding-right: 10px;

  width: 100%;
  display: flex;
  align-items: center;

  &:focus {
    outline: 0;
  }
  &:hover,
  &:focus {
    background-color: var(--gray);
  }
}
.ChartButtonControls-item-button-indicator {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  margin-left: 10px;
}
.ChartButtonControls-item-button--active {
  background-color: var(--gray);
}
