@import "~scss/imports";

.Report {
  display: grid;
  grid-template-areas:
    "calc-header"
    "report-header"
    "controls"
    "budget-changes"
    "taxcalc"
    "projections"
    "footer";

  .Report-header,
  .Report-changes,
  .Report-projections,
  .Report-taxcalc {
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @include breakpoint($editMobileBreakpoint) {
    grid-template-areas:
      "controls body"
      "controls calc-header"
      "controls report-header"
      "controls budget-changes"
      "controls taxcalc"
      "controls projections"
      "footer footer";
    grid-template-columns: 100px 1fr;
    grid-column-gap: 50px;

    .CalcHeader {
      margin-top: 43px;
      padding-right: 50px;
    }

    .Report-header,
    .Report-changes,
    .Report-projections,
    .Report-taxcalc {
      padding-left: 50px;
      padding-right: 100px;
      padding-top: 40px;

      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
}
.Report-taxcalc {
  grid-area: taxcalc;
}
.Report-changes {
  grid-area: budget-changes;
}

.Report-meta {
  grid-area: meta;
  @include breakpoint("md") {
    max-width: 30em;
  }

  .author {
    margin: 1em 0;
  }
}

.Report-icon {
  grid-area: icon;
  object-fit: contain;
  margin: auto;
  max-height: 8em;
  height: 100%;
  position: relative;

  img {
    display: block;
    height: 100%;
  }
}

.Report-outlook {
  grid-area: outlook;
  //text-align: center;
  display: grid;
  //grid-template-columns: 50% 50%;
  grid-column-gap: 1em;
  grid-row-gap: 1em;

  .font--body {
    line-height: 1.2;
  }
}

.Report-outlook-equation {
  display: grid;
  grid-gap: 0.5em;
  justify-items: center;
  align-items: center;

  @media (min-width: 500px) {
    grid-template-columns: 1fr auto 1fr auto 1fr;
  }
}
.Report-outlook-equation-symbol {
  font-size: 2.5em;
  font-family: var(--mono);
  padding-top: 0.12em;
}

.Report-outlook-debt {
  margin-top: 1em;
  border: 1px solid #dadada;
  border-radius: 0.4em;
  display: grid;
  padding: 1.5em;
  align-items: center;
  grid-template-areas:
    "value"
    "explainer";
  grid-gap: 1em;

  @media (min-width: 450px) {
    grid-template-columns: 1fr auto;
    grid-template-areas: "explainer value";
    grid-column-gap: 2em;
  }

  .Report-outlook-debt-value {
    grid-area: value;
  }
}
.Report-outlook-debt-explainer {
  @extend %report-body;
  font-size: 14px;
  grid-area: explainer;
}

.Report-detail {
  @include breakpoint($editMobileBreakpoint) {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "body"
      "body";
  }

  .Report-detail-header {
    align-items: center;
    justify-content: center;
    display: flex;
    grid-area: header;
    @extend %page-pad--large;
    padding-top: 0;
    padding-bottom: 0;
    height: 40px;
  }
  .Report-body {
    grid-area: body;
  }

  .Report-detail-header {
    position: sticky;
    top: 0;
    z-index: 3;
  }
}

.Report-body {
  h1 {
    margin-bottom: 1em;
  }
}

.Report-projections {
  grid-area: projections;

  .SuperHeroChart {
    margin-top: 2em;
  }
}

.BudgetChanges {
  .genre-tabs {
    position: sticky;
    top: 4em;
    z-index: 2;

    @include breakpoint("sm") {
      top: 0;
    }
  }

  .BudgetChange {
    margin-top: 4em;
  }
}

.BudgetChanges-genre {
  min-height: 12em;
}

.BudgetChange {
  display: grid;
  grid-auto-rows: min-content;
  grid-template-areas:
    "title"
    "snapshot"
    "changes";

  @include breaks("grid-gap", $sizes-outercontainer);

  @include breakpoint("smmed") {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "title title"
      "snapshot changes";
  }

  @include breakpoint("lg") {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "title title title"
      "snapshot changes changes";
  }

  .BudgetChange-title {
    grid-area: title;
  }
  .BudgetChange-snapshot {
    grid-area: snapshot;
  }
  .HeroChart {
    margin-top: 1em;
  }

  .BudgetChange-changes {
    grid-area: changes;
  }

  .BudgetChange-section {
    & + .BudgetChange-section {
      margin-top: 1em;
    }
  }
  .BudgetChange-item {
    & + .BudgetChange-item {
      margin-top: 1em;
    }
    .Policy-more-info {
      margin-top: 1em;
    }
    .Policy-impact {
      @extend %faded;
    }
  }
}

.Report-footer {
  grid-area: footer;
}

.EditMetaButton {
  display: flex;
  align-items: center;

  .cta {
    opacity: 0.5;
    transition: opacity 0.1s ease-out;
  }
  svg {
    display: block;
    width: 20px;
    height: 20px;
  }

  &:hover,
  &:focus {
    .cta {
      opacity: 1;
      text-decoration: underline;
    }
    outline: 0;
  }
}

.report-module {
  & + .report-module {
    border-top: 1px solid #dadada;
  }
}
