@import "~scss/imports";

.Report-header {
  grid-area: report-header;
  display: grid;
  grid-template-areas:
    "meta"
    "outlook"
    "tooltip";
  grid-gap: 1em;

  .Report-icon {
    display: none;
  }

  @media (min-width: 450px) {
    grid-template-areas:
      "meta"
      "outlook"
      "tooltip";

    .Report-icon {
      display: block;
      width: 100%;
      img {
        margin: 0 auto;
      }
    }
  }

  @media (min-width: 1200px) {
    grid-template-areas: "meta outlook" ". tooltip";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-column-gap: 4vw;
  }
}

.Report-header-heading {
  @extend %report-h1;
}
.Report-header-author {
  @extend %report-label;
  margin-top: 2em;
  margin-bottom: 1em;
}
.Report-header-description {
  @extend %report-body;
  margin-top: 1.25em;
}
.Report-header-notes {
  padding: 0;
  list-style-type: none;
  @extend %report-label;
  margin-top: 1.25em;
}
.Report-outlook-debt-value {
  text-align: center;
}
.Report-outlook-debt-value-label {
  white-space: nowrap;
  display: flex;
  justify-content: center;
}

.Report-outlook-tooltip {
  display: flex;
  grid-area: tooltip;
  font-family: var(--domaine);
  color: var(--black);
  margin-top: 1em;
}
