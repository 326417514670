@import "mixins";

%report-label {
  font-family: var(--basis);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 1.4;
}
%report-header-heading {
  font-family: var(--domaine);
  font-size: 36px;
  letter-spacing: 0;
  line-height: 1;
}
%report-bodylarge {
  font-family: var(--domaine);
  font-size: 18px;
  color: #000000;
  letter-spacing: 0;
  line-height: 28px;
}
%report-body {
  font-family: var(--domaine);
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  line-height: 24px;
}
%report-h1 {
  font-family: var(--domaine);
  font-size: 34px;
  letter-spacing: 0;
  line-height: 40px;
}
%report-category {
  font-family: var(--domaine);
  font-size: 24px;
  letter-spacing: 0;
  line-height: 21px;
  font-weight: bold;
}
%report-value {
  font-family: var(--mono);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}

%embed-h1 {
  font-family: var(--domaine);
  font-size: 36px;
  letter-spacing: 0;
  line-height: 36px;
}
%embed-h2 {
  font-family: var(--domaine);
  font-size: 21px;
  letter-spacing: 0;
  line-height: 28px;
}
%embed-body {
  font-family: var(--domaine);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}
%embed-choice {
  font-family: var(--mono);
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
}
%embed-button {
  font-family: var(--basis);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0;
  text-align: center;
  line-height: 28px;
}

$sizes-input: (
  "base": 16px,
  "xxs": 16px,
  "xs": 16px,
  "sm": 16px,
  "md": 16px,
  "lg": 16px,
  "xl": 16px,
  "xxl": 16px,
);
%font--input {
  font-family: var(--domaine);
  @include font-sizes($sizes-input);
  letter-spacing: -0.014em;
  line-height: 1.35rem;
}
$sizes-label: (
  "base": 10px,
  "xxs": 11px,
  "xs": 12px,
  "sm": 13px,
  "md": 14px,
  "lg": 14px,
  "xl": 14px,
  "xxl": 14px,
);
%font--label {
  font-family: var(--domaine);
  @include font-sizes($sizes-label);
}
%font--label--alt {
  font-family: var(--basis);
  @include font-sizes($sizes-label);
}
$sizes-label-small: (
  "base": 10px,
  "xxs": 10px,
  "xs": 10px,
  "sm": 10px,
  "md": 12px,
  "lg": 12px,
  "xl": 12px,
  "xxl": 12px,
);
%font--label--small {
  font-family: var(--basis);
  @include font-sizes($sizes-label-small);
}
$sizes-number: (
  "base": 15px,
  "xxs": 15px,
  "xs": 15px,
  "sm": 15px,
  "md": 15px,
  "lg": 15px,
  "xl": 15px,
  "xxl": 15px,
);
%font--number {
  font-family: var(--mono);
  @include font-sizes($sizes-number);
}

$sizes-body: (
  "base": 14px,
  "xxs": 14px,
  "xs": 14px,
  "sm": 14px,
  "md": 14px,
  "lg": 16px,
  "xl": 16px,
  "xxl": 17px,
);
%font--body {
  font-family: var(--domaine);
  @include font-sizes($sizes-body);
  letter-spacing: -0.014em;
  line-height: 1.35;

  @include breakpoint("md") {
    line-height: 1.75;
  }
}
$sizes-h1: (
  "base": 28px,
  "xxs": 28px,
  "xs": 28px,
  "sm": 28px,
  "md": 28px,
  "lg": 28px,
  "xl": 28px,
  "xxl": 28px,
);
%font--h1 {
  font-family: $ff-primary;
  @include font-sizes($sizes-h1);
}
