@import "~scss/imports";

.rc-slider-tooltip {
  z-index: 3;
}

.growth-slider {
  $rail-thickness: 2px;
  $rail-tail-height: 20px;
  $handle-diameter: 16px;
  min-width: 150px;

  .rc-slider-rail {
    height: $rail-thickness;

    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }

  .rc-slider-track {
    display: none;
    background-color: red;
    height: $rail-thickness;
  }

  .rc-slider-handle {
    color: $white;
    border-color: $white;
    border-width: $rail-thickness;
    height: $handle-diameter;
    margin-top: -7px;
    width: $handle-diameter;
    transition: transform $transition-timing;

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
      transform: translateZ(0) scale(1.3);
    }
  }
}

.growth-slider-tooltip {
  .rc-slider-tooltip {
    padding: 0;
  }

  .rc-slider-tooltip-inner {
    background-color: transparent;
    box-shadow: unset;
    height: auto;
  }

  .rc-slider-tooltip-arrow {
    display: none;
  }
}
