@import "mixins";
@import "variables";

%page-pad {
  @include paddings($sizes-pagepad);
}

$sizes-pagepad-large: (
  "base": 10px,
  "xxs": 10px,
  "xs": 10px,
  "sm": 12px,
  "md": 22px,
  "lg": 28px,
  "xl": 28px,
  "xxl": 28px,
);
%page-pad--large {
  @include paddings($sizes-pagepad-large);
}
