@import "~scss/imports";

.FeaturedCalculators,
.FeaturedArticles {
  padding-right: 8vw;
}
.FeaturedCalculators {
  width: 100%;

  .CalculatorTile {
    min-height: 100%;
    width: 100%;
    //height:100%;
    display: flex;
    flex-direction: column;

    @include breakpoint("md") {
      width: calc(50% - 5px);
    }

    @include breakpoint("lg") {
      width: calc(33.33% - 5px);
    }

    @include breakpoint("xxl") {
      width: calc(25% - 10px);
    }

    .CalculatorTile-inner {
      width: 100%;
      flex: 1;
    }

    & + .CalculatorTile {
      margin-left: 10px;
    }
  }

  .flickity-page-dots {
    padding: 10px 0;
    position: static;
  }
  .flickity-prev-next-button.previous {
    left: -30px;
  }
  .flickity-prev-next-button.next {
    right: -30px;
  }
  .flickity-prev-next-button[disabled] {
    opacity: 0;
  }

  .count--2 {
    .CalculatorTile {
      @include breakpoint("lg") {
        width: calc(50% - 5px);
      }
    }
  }

  .count--3,
  .count--2 {
    .CalculatorTile {
      @include breakpoint("xxl") {
        width: calc(33.33% - 5px);
      }
    }
  }
}

.Calculators-table {
  width: 100%;

  td,
  th {
    min-width: 3em;
    padding: 0.75rem 0.5rem;
  }

  td {
    border-top: 1px solid $dark-gray;
  }
}

.FeaturedCalculators-carousel {
  margin-top: 2em;
  padding: 0;
}

.table-link,
.table-button {
  display: block;
  width: 25px;
  height: 25px;
  margin: auto;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.table-button {
  @extend %reset-button;
}

.Calculators {
  display: grid;
  grid-template-areas:
    "heading . newcalc"
    "calcs calcs calcs";

  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr;

  .heading {
    grid-area: heading;
  }
  .new-calc {
    grid-area: newcalc;
    display: inline-flex;
    align-items: center;
    align-self: start;

    .rrs {
      margin-left: 1em;

      .rrs__button {
        min-width: 0;
      }
    }
  }
  .calculator-list {
    grid-area: calcs;
  }
}
