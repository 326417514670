@import "~scss/imports";

.CategoryDetail {
  background-color: white;
  border-left: 1px solid $gray;

  .category-header {
    flex-direction: column;
    align-items: center;
  }
  .category-header-values {
    margin: 0 auto;
  }
  .settings-wrap,
  .category-header-values {
    margin-top: 0.5em;
    @include breakpoint("md") {
      margin-top: 2em;
    }
  }
  .GrowthRateSettings {
    display: inline-grid;
  }

  .detail-section {
    margin-top: 2em;
  }
}

.DoneEditing {
  background-image: linear-gradient(-180deg, $white 0%, rgba($white, 0) 100%);
  align-items: center;
  justify-content: flex-end;
  display: flex;
  padding: 16px 10px;
  position: relative;
  line-height: 1;
  position: sticky;
  top: #{$tabHeight + $genreTabHeight};
  z-index: 1;

  > svg {
    margin-left: 0.5em;
    width: 3em;
  }

  &:hover {
    text-decoration: underline;
  }

  @include breakpoint("sm") {
    padding: 16px 20px;
  }

  @include breakpoint("md") {
    top: $genreTabHeight;
  }
}
