@import "~scss/imports";

.react-tabs__tab {
  height: $genreTabHeight !important;
  min-height: 0 !important;
  padding: 0 !important;
}

.tab {
  @extend %tab;
}

.round-button {
  text-align: center;

  svg {
    fill: inherit;
    display: block;
    width: 100%;
    height: 100%;
  }

  .button-circle {
    background-color: $black;
    border-radius: 50%;
    margin: auto;
    width: calc(#{$tabHeight} - 2em);
    height: calc(#{$tabHeight} - 2em);
    fill: $user-color;
    position: relative;
    transition: transform $transition-timing;

    a:focus &,
    button:focus & {
      border-radius: 50%;
      border: 3px solid $user-color;
    }
  }
  &:hover,
  .react-tabs__tab--selected & {
    .button-circle {
      background-color: $user-color;
      fill: $black;
    }
  }

  &:hover .button-circle {
    transform: scale(1.1);
  }

  .button-label {
    font-size: 70%;
    margin-top: 0.125em;
  }
}

.button-inline {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  text-align: center;

  &:focus {
    outline: 0;
  }
}

a.button-inline:focus {
  outline: 0;
}

.DisplayYearSlider {
  &.horizontal {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .year-slider {
      width: calc(100% - 4em);
    }
  }
  &.vertical {
    height: 100%;

    .year-slider {
      height: calc(100% - 4em);
    }
  }
}

.year-slider {
  position: relative;

  .handle {
    border: 1px solid;
    background-color: $black;
    cursor: grab;
    color: $gray;
    padding: 5px;

    &:focus {
      outline: 3px solid $user-color;
    }
  }
  .bar {
    border-radius: 10px;
  }

  .bar-0 {
    background-color: $black;
  }
  .bar-1 {
    background-color: $dark-gray;
  }

  &.horizontal {
    width: 100%;
    height: 2em;

    .handle,
    .bar {
      top: 50%;
      transform: translateY(-50%);
    }
    .bar {
      height: 5px;
    }
  }

  &.vertical {
    width: 4em;

    .handle,
    .bar {
      left: 50%;
      transform: translateX(-50%);
    }
    .bar {
      width: 5px;
    }
  }
}

.display-year-value {
  .rrs__button {
    min-width: 0;
  }

  .rrs__option {
    padding: 0.25em 0.5rem;
    line-height: inherit;
    min-height: 1em;
  }

  @include breakmax("lg") {
    .rrs--options-visible .rrs__label,
    .rrs__button:focus .rrs__label {
      box-shadow: none;
    }
    .rrs__button {
      line-height: inherit;
      padding: 0 0.25em;
      min-height: 1em;
      z-index: 3;

      & + .rrs__options {
        top: 1em;
      }

      .rrs__label {
        padding: 0;
      }
    }
  }
}

.external-link {
  text-decoration: none;
  white-space: nowrap;
  .external-link-text {
    text-decoration: underline;
  }
  &:after {
    content: " ↗";
    font-size: 0.75em;
  }
}

.slide-toggle-button {
  $size: 5px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  padding: 0;
  border-radius: 0;

  .label {
    margin-right: 0.75rem;
  }

  &:focus {
    outline: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  > svg {
    content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 8"><path d="M1.757 2L6 6.243 10.243 2" stroke="#111" stroke-width="2" fill="none"></path></svg>');
    display: inline-block;
    width: 1em;
    height: 1em;
    transition: transform $transition-timing;
    transform-origin: center;
  }

  &.is-open > svg {
    transform: rotate(180deg);
  }
}

.slide-toggle-content {
  opacity: 0;
  transition: opacity 0.5s;

  &.is-open {
    opacity: 1;
  }
}

.slide-toggle-button-user {
  padding: 5px;
  &:after {
    margin-left: 0;
  }
}

.button {
  @extend %button;
}

.button--default {
  border-color: $black;
}

.icon-button {
  @extend %reset-button;
}

.link-button {
  &:hover {
    text-decoration: underline;
  }
}

.button-inline {
  @extend %reset-button;
  display: flex;
  align-items: center;

  > svg {
    margin-right: 8px;
  }
}

.cta--large {
  @extend %cta--large;
}

.chart-data-select {
  .rrs__button {
    min-width: 0;
  }

  .rrs__option {
    padding: 0;

    .option-inner {
      align-items: center;
      display: flex;
      @extend %font--label--small;
      padding: 0.5rem;
    }

    .option-inner-marker {
      margin-right: 0.5em;
      width: 1em;
      height: 1em;
    }
  }
}
.rrs__button + .rrs__options {
  z-index: 4;
}
$rrsHeight: 30px;
.rrs__button {
  border-radius: 0;
  border-width: 1px;
  border-style: solid;
  box-shadow: none;
  display: flex;
  line-height: 1;
  height: auto;
  min-height: $rrsHeight;
  min-width: 160px;
}

.rrs--options-visible .rrs__label {
}
.rrs--options-visible .rrs__label,
.rrs__button:focus .rrs__label {
  border: 0;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
}

.rrs__button + .rrs__options {
  top: $rrsHeight;
}
.rrs__label {
  border: 0;
  padding: 0 0.5rem;
  align-items: center;

  svg {
    margin-left: auto;
  }
}
.rrs__label__text {
  color: inherit;
  padding-right: 0.5rem;
}

.GrowthRateSelect {
  .rrs__button {
    color: inherit;
  }
  .rrs__option {
    font-family: inherit;
    text-align: left;
  }
}

.__react_component_tooltip {
  @extend %font--body;
  //max-width: 300px;
}

.rc-slider {
  &.slider--black {
    .rc-slider-track,
    .rc-slider-handle {
      border-color: $white;
      background-color: $black;
    }
  }
}

.__react_component_tooltip.chart-tooltip,
.__react_component_tooltip.tooltip--info,
.__react_component_tooltip.type-info {
  background-color: $white !important;
  filter: drop-shadow(2px 2px 7px rgba($black, 0.5));
  border-radius: 0;
  color: $black !important;
  overflow: visible;
  opacity: 1;

  &.place-top {
    &:after {
      border-top-color: $white !important;
    }
  }
  &.place-bottom {
    &:after {
      border-bottom-color: $white !important;
    }
  }
  &.place-left {
    &:after {
      border-left-color: $white !important;
    }
  }
  &.place-right {
    &:after {
      border-right-color: $white !important;
    }
  }
}

.__react_component_tooltip.tooltip--info,
.__react_component_tooltip.type-info {
  max-width: 250px;
  width: 100%;
}

.react-confirm-alert {
  width: 100%;
  height: 100%;
  display: flex;
}

.AlertModal {
  max-width: 600px;
  margin: auto;
}

.AlertModal-list {
  list-style-type: none;
  padding: 0;
}

.AlertModal-buttons {
  .button + .button {
    margin-left: 1em;
  }
}

.AlertModal-list,
.AlertModal-buttons {
  margin-top: 2em;
}

.ReactModal__Content {
  margin: 1em;
  background-color: transparent !important;
  border: 0 !important;
  display: flex;
  align-items: center;
  // height: auto;
  // min-height: 15em;
  // max-width: 800px;
  // max-height: 600px;
  margin: auto;

  // top: 30px!important;
  // bottom: 30px!important;
  // left: 30px!important;
  // right: 30px!important;
}

.ReactModal__Overlay {
  background-color: rgba(255, 255, 255, 0.9) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Global Firebase StyledFirebaseAuth overrides

.firebaseui-idp-list {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.firebaseui-list-item {
  margin: 0 auto;
  // margin-bottom: 0!important;

  .firebaseui-idp-button {
    margin: 0 !important;
    min-width: 200px;
    border: 0;
  }
}

@mixin flickity($button-size) {
  $button-size: 40px;
  padding: 0 $button-size;

  .flickity-prev-next-button {
    width: $button-size;
    background-color: transparent;
  }
  .flickity-prev-next-button.previous {
    left: 0;
  }

  .flickity-prev-next-button.next {
    right: 0;
  }
}
.flickity {
  @include flickity(40px);
}

.flickity--arrows-outside {
  @include flickity(20px);
}

//React Tabs used for sidebar
.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    //border-bottom: 1px solid $black;
    margin: 0 0 10px;
    padding: 0;
  }

  &__tab {
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;

    align-items: center;
    display: flex;
    justify-content: center;
    min-height: $tabHeight;
    width: 100%;

    &--selected {
      background-color: $white;
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      box-shadow: 0 0 5px hsl(208, 99%, 50%);
      border-color: hsl(208, 99%, 50%);
      outline: none;

      &:after {
        content: "";
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: #fff;
      }
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}

.SaveButton {
  border: 0;
  background-color: inherit;
  color: inherit;
  padding: 0;
  display: block;
}
