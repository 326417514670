@import "~scss/imports";

.Sidebar-block {
  @include breaks("padding-top", $sizes-pagepad);
  @include breaks("padding-bottom", $sizes-pagepad);
  @include breakpoint("sm") {
    & + .Sidebar-block {
      border-top: 1px solid $gray;
    }
  }

  .button-inline + .button-inline {
    margin-top: 0.5em;
  }
}

.Sidebar .BudgetMeta {
  .description {
    margin-top: 1em;
  }
}

.Sidebar {
  background-color: $user-ultralight;
  height: 100vh;
  position: relative;

  // .change-count {
  //   position: absolute;
  //   top: 50%;
  //   right: 50%;
  //   transform: translate(2rem,-1.1rem);
  // }

  .react-tabs__tab-panel {
    min-height: 100vh;
  }

  .year-slider {
    width: 100%;
  }
}

.Sidebar--desktop {
  position: sticky !important;
  top: 0;
  height: 100vh !important;
}

.Sidebar-tablist {
  background-color: $black;
  color: $white;
  fill: $white;

  display: grid;
  grid-template-columns: repeat(4, 25%);
  position: sticky;
  top: 0;
  z-index: 2;

  @include breakpoint($editMobileBreakpoint) {
    grid-template-columns: repeat(3, 33.33%);
  }

  .react-tabs__tab {
    background-color: inherit;
    color: inherit;
    fill: inherit;

    & + .react-tabs__tab {
      //border-left: 1px solid rgba($light-gray,.25);
    }

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
      outline: 0;

      &:after {
        content: none;
      }
    }
  }

  .react-tabs__tab:hover {
    background-color: $dark-gray;
    color: $black;
    fill: $black;
  }
  .react-tabs__tab--selected {
    color: $black;
    fill: $black;
    background-color: $light-gray;
  }
}

.change-count {
  align-items: center;
  justify-content: center;
  display: inline-flex;
  border-radius: 50%;
  min-width: 1rem;
  min-height: 1rem;
  //margin-left: 0.5em;
  font-size: 70%;
}
