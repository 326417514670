@import "~scss/imports";
.DefaultEdit {
  $margin: 2rem;

  .GenreGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    grid-column-gap: $margin;
    grid-row-gap: $margin;
    padding: $margin 0;
  }

  .link {
    background-color: $white;
    color: inherit;
    padding: $margin;
    width: 100%;
    grid-column: span 2;
    text-align: center;
    text-decoration: none;
  }

  @include breakpoint("md") {
    .half {
      grid-column: span 1;
    }
  }
}
