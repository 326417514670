$white: #fff;
$black: #333;
$light-gray: #f4f6f8;
$gray: #e9edf0;
$dark-gray: #dadbdd;
$pale: #e8edf0;
$mint: #00ccb6;
$orange: #ff5a42;
$forest: #013f1a;
$emerald: #056229;
$teal: #4ea79a;
$trueblack: #000;

$border-color: #c2c2c2;

$blue-primary: #2e07a9;
$blue-secondary: #98e7ff;
$blue-bright: #36c4ee;
$blue-muted: #eae6f6;
$blue-dim: #e8f6fc;

$green-primary: #1a8446;
$green-secondary: #b0ffcd;
$green-bright: #25df71;
$green-muted: #ddede3;

$spending-baseline-color: $blue-primary;
$spending-secondary-color: $blue-secondary;
$spending-user-color: $blue-bright;
$spending-muted-color: $blue-muted;

$revenue-baseline-color: $green-primary;
$revenue-secondary-color: $green-secondary;
$revenue-user-color: $green-bright;
$revenue-muted-color: $green-muted;

$neutral-user-color: $orange;
$neutral-baseline-color: $black;
$text-over-shape-color: $white;

$user-color: $blue-bright;
$user-light: $blue-dim;
$user-ultralight: #f7f9fb;

$baseline-color: #264c64;

$label-color: $black;

$chart-bg: $pale;
$bg-empty: $gray;

$bg-alt: $pale;

$tour: #ffb34d;

$colors: (
  ("spending", $spending-baseline-color),
  ("revenue", $revenue-baseline-color),
  ("economy", $teal),
  ("user", $user-color),
  ("userLight", $user-light),
  ("userUltralight", $user-ultralight),
  ("baseline", $baseline-color),
  ("debt", $orange),
  ("black", $black),
  ("white", $white),
  ("darkGray", $dark-gray),
  ("widget", $light-gray),
  ("body", $gray),
  ("label", $label-color),
  ("trueblack", $trueblack),
  ("tour", $tour),
  ("blog", $blue-primary)
);
