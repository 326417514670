@import "~scss/imports";

@each $color in $colors {
  $key: #{nth($color, 1)};
  $value: #{nth($color, 2)};
  .color--#{$key} {
    color: $value;
  }
  .bg--#{$key} {
    background-color: $value;
  }
  button.bg--#{$key} {
    &:focus,
    &:active,
    &:hover {
      background-color: darken(nth($color, 2), 15%);
      outline: 0;
    }
  }
  .fill--#{$key} {
    fill: $value;

    &.darken-on-hover {
      &:hover,
      &.is-hovered {
        transition: fill $transition-timing;
        fill: darken(nth($color, 2), 15%);
      }
    }
  }
  .stroke--#{$key} {
    stroke: $value;
  }
  .border-color--#{$key} {
    border-color: $value;
  }
  .hover-color--#{$key}:not(.active) {
    &:hover,
    &:focus {
      color: $value;
    }
  }
  .hover-bg--#{$key}:not(.active) {
    &:hover,
    &:focus {
      background-color: $value;
    }
  }
}

.fill--gdp {
  fill: url(#gdpgradient);
}

.fill--gdp--solid {
  fill: rgba($orange, 0.5);
}

.faded {
  @extend %faded;
}
