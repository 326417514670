@import "~scss/imports";

.ViewBudget-header {
  padding-top: 2em;
  padding-bottom: 2em;

  display: grid;
  grid-template-areas:
    "meta"
    "outlook";

  .ViewBudget-icon {
    display: none;
  }

  @media (min-width: 450px) {
    grid-template-areas:
      "meta meta"
      "icon outlook";
    grid-template-columns: 50% 50%;

    .ViewBudget-icon {
      display: block;
      width: 100%;
      img {
        margin: 0 auto;
      }
    }
  }

  @media (min-width: 700px) {
    grid-template-areas:
      "meta icon"
      "meta outlook";
    grid-template-rows: auto 1fr;
    grid-column-gap: 1em;
  }

  @include breakpoint("xxl") {
    align-items: center;
    grid-template-areas: "icon meta outlook";
    grid-template-columns: 20% auto 1fr;

    .ViewBudget-icon {
      //width: auto;
      //padding: 1em;

      img {
        max-width: 100%;
        height: 100%;
      }
    }
  }
}

.ViewBudget-meta {
  grid-area: meta;
  @include breakpoint("md") {
    max-width: 30em;
  }

  .author {
    margin: 1em 0;
  }
}

.ViewBudget-icon {
  grid-area: icon;
  object-fit: contain;
  margin: auto;
  max-height: 8em;
  height: 100%;
  position: relative;

  img {
    display: block;
    height: 100%;
  }
}

.ViewBudget-outlook-debt {
  grid-area: outlook;
  padding: 1em;
  text-align: center;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 1em;
}

.ViewBudget-detail {
  .ViewBudget-control {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 0.5rem;

    @include breakpoint($editMobileBreakpoint) {
      padding: 0.5rem 0;
    }
  }

  .ViewBudget-control-buttons {
    display: flex;
    align-items: stretch;

    .round-button .button-circle {
      width: 25px;
      height: 25px;
    }

    .button-inline {
      @extend %page-pad;
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;

      &:hover {
        background-color: $dark-gray;
      }

      & + .button-inline {
        margin-left: 1em;
      }

      &.download {
        display: none;
      }
    }
  }

  .ViewBudget-controls {
    grid-area: controls;
    height: 4rem;
  }
  .ViewBudget-controls-spacer {
    grid-area: controlsspacer;
  }

  .ViewBudget-detail-header {
    align-items: center;
    justify-content: center;
    display: flex;
    grid-area: header;
    @extend %page-pad--large;
    padding-top: 0;
    padding-bottom: 0;
    height: 40px;
  }
  .ViewBudget-body {
    grid-area: body;
  }

  .ViewBudget-controls,
  .ViewBudget-detail-header {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  @include breakpoint($editMobileBreakpoint) {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "controlsspacer body"
      "controls body";

    .ViewBudget-controls {
      flex-direction: column;
      height: 100vh;
    }

    .ViewBudget-control {
      flex-direction: column;
      width: auto;
      height: 100%;

      .button-inline + .button-inline {
        margin-top: 4vh;
        margin-left: 0;
      }
    }

    .ViewBudget-control-buttons {
      display: flex;
      flex-direction: column;

      .button-inline {
        margin: 0;

        &.download {
          display: flex;
        }
      }

      .round-button .button-circle {
        width: 50px;
        height: 50px;
      }
    }
  }
}

.ViewBudget-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .DisplayYearSlider.vertical {
    max-height: 300px;
  }
}

.ViewBudget-controls-spacer {
  @include breakpoint($editMobileBreakpoint) {
    padding-top: calc(
      4.25rem - #{map-get($sizes-pagepad, $editMobileBreakpoint)}
    );
  }

  @include breakpoint("xl") {
    padding-top: calc(4.25rem - #{map-get($sizes-pagepad, "xl")});
  }

  @include breakpoint("xxl") {
    padding-top: calc(4.25rem - #{map-get($sizes-pagepad, "xxl")});
  }
}

.ViewBudget-body {
  h1 {
    margin-bottom: 1em;
  }
}

.ViewBudget-charts {
  align-self: start;
  padding-top: 4em;
  padding-bottom: 4em;

  .HeroChart {
    margin-top: 2em;
  }

  @include breakpoint("smmed") {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include breaks("grid-gap", $sizes-outercontainer);

    .HeroChart {
      margin-top: 0;
    }
  }

  @include breakpoint("lg") {
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    .HeroChart,
    .ViewBudget-snapshot {
      grid-column: span 2;
    }

    .ViewBudget-debt {
      grid-column: span 4;
    }
  }
}

.BudgetChanges {
  .genre-tabs {
    position: sticky;
    top: 4em;
    z-index: 2;

    @include breakpoint("sm") {
      top: 0;
    }
  }

  .BudgetChange {
    margin-top: 4em;
  }
}

.BudgetChanges-genre {
  min-height: 12em;
}

.BudgetChange {
  display: grid;
  grid-auto-rows: min-content;
  grid-template-areas:
    "title"
    "snapshot"
    "changes";

  @include breaks("grid-gap", $sizes-outercontainer);

  @include breakpoint("smmed") {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "title title"
      "snapshot changes";
  }

  @include breakpoint("lg") {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "title title title"
      "snapshot changes changes";
  }

  .BudgetChange-title {
    grid-area: title;
  }
  .BudgetChange-snapshot {
    grid-area: snapshot;
  }
  .HeroChart {
    margin-top: 1em;
  }

  .BudgetChange-changes {
    grid-area: changes;
  }

  .BudgetChange-section {
    & + .BudgetChange-section {
      margin-top: 1em;
    }
  }
  .BudgetChange-item {
    & + .BudgetChange-item {
      margin-top: 1em;
    }
    .Policy-more-info {
      margin-top: 1em;
    }
    .Policy-impact {
      @extend %faded;
    }
  }
}

.EditMetaButton {
  display: flex;
  align-items: center;

  .cta {
    opacity: 0.5;
    transition: opacity 0.1s ease-out;
  }
  svg {
    display: block;
    width: 20px;
    height: 20px;
  }

  &:hover,
  &:focus {
    .cta {
      opacity: 1;
      text-decoration: underline;
    }
    outline: 0;
  }
}
