@import "imports";

html,
body {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: unset;
  margin: 0;
  line-height: 1.1;
}

.reset-button {
  @extend %reset-button;
}
.reset-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.reset-link {
  @extend %reset-link;
}
.reset-input {
  border: 0;
  padding: 0;
}
.reset-select {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  appearance: none;
  font: inherit;
}
