@import "~scss/imports";

.ReportChanges-genre {
  margin-top: 50px;
}
.ReportChanges-genre-heading {
  @extend %report-label;
  margin-bottom: 12px;
}

.BudgetChanges-nochanges {
  @extend %report-body;
  margin-top: 2em;
}
