@import "~scss/imports";

.PolicyList {
  padding: 0;
  border: 1px solid $border-color;
  list-style-type: none;
}

.Policy {
  @include breakpoint("md") {
    align-items: center;
    display: flex;
    position: relative;
  }

  a {
    color: inherit;
  }

  .PolicyList & {
    padding: 10px;
    & + .Policy {
      border-top: 1px solid $border-color;
    }
    @include breakpoint("md") {
      padding: 20px 40px;
    }
  }

  // .policy-pad {
  // 	padding-top: 15px;
  // 	padding-bottom: 15px;
  // }

  .explainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 2em;

    .color--label {
      @include breakpoint("md") {
        font-size: 17px;
      }
    }
  }

  .external-link {
    display: inline-block;
    margin-top: 1em;
  }

  .enabled-message,
  .disabled-message {
    margin-top: 1em;
    opacity: 0.75;
    font-size: 0.75em;
  }

  .policy-name {
    font-style: italic;
  }

  .Switch {
    margin-left: auto;
    margin-right: 1rem;
    margin-top: 1em;
    @include breakpoint("md") {
      margin-top: 0;
    }
    .react-switch-bg {
      line-height: 100%;
      border: 1px solid gray;
    }
  }

  &.is-enacted {
    background-color: $user-light;
  }
}

.Policy-impact {
  margin-top: 0.5em;
}

.Policy-more-info {
  margin-top: 2.5em;
}

.Policy-description,
.Policy-amounts {
  margin-top: 1.5em;
}

.Policy-amount {
  padding: 0 0.5em;
}
