@import "~scss/imports";

.CategoryChanges {
  --desktop-columns: 15fr 8em 1fr 45% auto;
  & + .CategoryChanges {
    margin-top: 18px;
  }
}

.CategoryChanges-header {
  display: grid;
  grid-gap: 1em;

  grid-template-areas:
    "heading heading carrot"
    "changecount effect carrot";

  @media (min-width: 1000px) {
    grid-template-areas: "heading changecount effect carrot";
    grid-template-columns: 20em 8em auto 1fr;
  }

  border-top-width: 1px;
  border-top-style: solid;

  padding-top: 0.75em;
  padding-bottom: 1em;

  img {
    grid-area: carrot;
    justify-self: end;
    align-self: center;
  }
}
.CategoryChanges-header-heading {
  grid-area: heading;
  @extend %report-category;
}

.CategoryChanges-header-changecount {
  grid-area: changecount;
  @extend %report-label;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  justify-self: start;
  align-self: start;
}
.CategoryChanges-header-effect {
  grid-area: effect;
}
.CategoryChanges-header-effect-label {
  @extend %report-label;
}
.CategoryChanges-header-effect-value {
  @extend %report-value;
  margin-left: 1em;
}

.CategoryChanges-body {
  display: grid;
  grid-template-areas:
    "changes"
    "chart";

  grid-gap: 2em;

  margin-top: 30px;
  margin-bottom: 50px;

  @media (min-width: 600px) {
    grid-template-areas: "changes chart";
    grid-template-columns: 15em 1fr;
  }

  @media (min-width: 1000px) {
    grid-gap: 8vw;
  }
}

.CategoryChanges-body-changes {
  grid-area: changes;
}
.CategoryChanges-body-snapshot {
  grid-area: snapshot;

  .category-header-value-item {
    flex-direction: column;
    align-items: flex-start;

    & + .category-header-value-item {
      margin-top: 1.5rem;
    }

    .label {
      text-align: left;
      margin-right: 0;
      color: inherit;
      @extend %faded;
      @extend %report-label;
    }
    .value {
      margin-left: 0;
    }
  }
}
.CategoryChanges-body-chart {
  grid-area: chart;
}

.BudgetChange-section {
  @extend %report-label;

  & + .BudgetChange-section {
    margin-top: 1.5rem;
  }
}
.BudgetChange-section-heading {
  margin-bottom: 0.5em;
}
.BudgetChange-section-value {
  @extend %report-value;
}
