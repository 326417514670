@import "imports";

@font-face {
  font-family: "basis-grotesque-medium";
  src: url("~fonts/basis-grotesque-medium-web/basis-grotesque-medium.eot");
  src: url("~fonts/basis-grotesque-medium-web/basis-grotesque-medium.eot?#iefix")
      format("embedded-opentype"),
    url("~fonts/basis-grotesque-medium-web/basis-grotesque-medium.woff")
      format("woff"),
    url("~fonts/basis-grotesque-medium-web/basis-grotesque-medium.woff2")
      format("woff2"),
    url("~fonts/basis-grotesque-medium-web/basis-grotesque-medium.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "basis-grotesque-mono";
  src: url("~fonts/basis-grotesque-mono-web/basis-grotesque-mono.eot");
  src: url("~fonts/basis-grotesque-mono-web/basis-grotesque-mono.eot?#iefix")
      format("embedded-opentype"),
    url("~fonts/basis-grotesque-mono-web/basis-grotesque-mono.woff")
      format("woff"),
    url("~fonts/basis-grotesque-mono-web/basis-grotesque-mono.woff2")
      format("woff2"),
    url("~fonts/basis-grotesque-mono-web/basis-grotesque-mono.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "domaine-sans-regular";
  src: url("~fonts/Domaine Sans Web/DomaineSansTextWeb-Regular.eot");
  src: url("~fonts/Domaine Sans Web/DomaineSansTextWeb-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("~fonts/Domaine Sans Web/DomaineSansTextWeb-Regular.woff")
      format("woff"),
    url("~fonts/Domaine Sans Web/DomaineSansTextWeb-Regular.woff2")
      format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "domaine-sans-regular";
  src: url("~fonts/Domaine Sans Web/DomaineSansTextWeb-Bold.eot");
  src: url("~fonts/Domaine Sans Web/DomaineSansTextWeb-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("~fonts/Domaine Sans Web/DomaineSansTextWeb-Bold.woff") format("woff"),
    url("~fonts/Domaine Sans Web/DomaineSansTextWeb-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

:root {
  --domaine: "domaine-sans-regular", sans-serif;
  --basis: "basis-grotesque-medium", sans-serif;
  --mono: "basis-grotesque-mono", monospace;
}

.font--body {
  @extend %font--body;
}

.font--input {
  @extend %font--input;
}
// .font--body--alt {
//   font-family: $ff-secondary;
//   @include font-sizes($sizes-body);
//   letter-spacing: -0.014em;
//   line-height: 1.55;
// }

$sizes-body-small: (
  "base": 14px,
  "xxs": 14px,
  "xs": 14px,
  "sm": 14px,
  "md": 14px,
  "lg": 14px,
  "xl": 14px,
  "xxl": 14px,
);
.font--body--small {
  font-family: $ff-primary;
  @include font-sizes($sizes-body-small);
  letter-spacing: -0.014em;
  line-height: 1.55;
}
.font--body--alt--small {
  font-family: $ff-secondary;
  @include font-sizes($sizes-body-small);
  letter-spacing: -0.014em;
  line-height: 1.55;
}

$sizes-huge: (
  "base": 30px,
  "xxs": 30px,
  "xs": 30px,
  "sm": 40px,
  "md": 40px,
  "lg": 60px,
  "xl": 60px,
  "xxl": 60px,
);
.font--huge {
  font-family: $ff-primary;
  line-height: 1.1em;
  @include font-sizes($sizes-huge);
}

.font--h1 {
  @extend %font--h1;
}

$sizes-h2: (
  "base": 20px,
  "xxs": 20px,
  "xs": 20px,
  "sm": 27px,
  "md": 27px,
  "lg": 27px,
  "xl": 27px,
  "xxl": 27px,
);
.font--h2 {
  font-family: $ff-primary;
  @include font-sizes($sizes-h2);
}

$sizes-h3: (
  "base": 16px,
  "xxs": 16px,
  "xs": 16px,
  "sm": 20px,
  "md": 20px,
  "lg": 20px,
  "xl": 20px,
  "xxl": 20px,
);
.font--h3 {
  font-family: $ff-primary;
  @include font-sizes($sizes-h3);
}

$sizes-h4: (
  "base": 15px,
  "xxs": 15px,
  "xs": 15px,
  "sm": 15px,
  "md": 15px,
  "lg": 15px,
  "xl": 15px,
  "xxl": 15px,
);
.font--h4 {
  font-family: $ff-primary;
  @include font-sizes($sizes-h4);
}
.font--label--small {
  @extend %font--label--small;
}
.font--label--alt {
  @extend %font--label--alt;
}
.font--label {
  @extend %font--label;
}
.font--number {
  @extend %font--number;
}

// $sizes-number--large: (
//   'base': 16px,
//   'xxs': 16px,
//   'xs': 16px,
//   'sm': 16px,
//   'md': 16px,
//   'lg': 24px,
//   'xl': 24px,
//   'xxl': 24px
// );
.font--number--large {
  font-family: $ff-mono;
  @include font-sizes($sizes-h3);
}

$sizes-number--huge: (
  "base": 32px,
  "xxs": 32px,
  "xs": 42px,
  "sm": 42px,
  "md": 42px,
  "lg": 52px,
  "xl": 52px,
  "xxl": 52px,
);
.font--number--huge {
  font-family: $ff-mono;
  @include font-sizes($sizes-number--huge);
}

$sizes-number--small: (
  "base": 13px,
  "xxs": 13px,
  "xs": 13px,
  "sm": 13px,
  "md": 13px,
  "lg": 13px,
  "xl": 13px,
  "xxl": 13px,
);
.font--number--small {
  font-family: $ff-mono;
  @include font-sizes($sizes-number--small);
}

$sizes-number--axis: (
  "base": 11px,
  "xxs": 11px,
  "xs": 11px,
  "sm": 11px,
  "md": 11px,
  "lg": 11px,
  "xl": 11px,
  "xxl": 11px,
);
.font--number--axis {
  font-family: $ff-mono;
  @include font-sizes($sizes-number--axis);
}

.text-align--center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}
