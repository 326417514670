@import "~scss/imports";

.question {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.text {
  @extend %embed-body;
}

.choices {
  margin-top: 1rem;
  display: grid;
  grid-gap: 1em;
  align-self: start;
  grid-template-columns: auto 1fr;

  @media (min-width: 500px) {
    margin-top: 2rem;
  }

  @media (min-width: #{$embedBreakpoint}) {
    grid-template-columns: auto 1fr;
  }
}
.choicesItem {
  @extend %embed-choice;
  min-width: 4em;
  cursor: pointer;

  input[type="radio"] {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;

    &:focus + .choicesItemLabel:before {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
    }
    &:checked + .choicesItemLabel:before {
      background-color: #000;
    }
  }
}
.choicesItemLabel {
  display: flex;
  align-items: center;
}
.choicesItemLabel:before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 2px solid;
  margin-right: 0.5em;
}

.next {
  flex-shrink: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  @extend %embed-button;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
  padding: 0.5rem 1rem 0.5rem 0;
  border: 1px solid transparent;

  @media (min-width: 500px) {
    padding: 1em 2em 1em 0;
  }

  &:focus {
    outline: 0;
    border-radius: 5px;
    border-color: #dadada;
  }

  :global(.next-arrow-icon) {
    display: flex;
    align-items: center;
    margin-left: 1em;
    color: #000;
    stroke: currentColor;
  }

  &:global(.active) {
    opacity: 1;

    :global(.next-arrow-icon) {
      animation: float 1s linear infinite alternate;
    }
  }

  @media (min-width: #{$embedBreakpoint}) {
    margin-top: auto;
  }
}

@keyframes float {
  from {
    transform: translateX(0);
  }
  to {
    transform: scale(1.05) translateX(30%);
  }
}
