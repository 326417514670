@import "~scss/imports";

.SuperHeroChart {
  svg * {
    vector-effect: non-scaling-stroke;
  }

  .chart-header {
    .RadioGroup-item input[type="radio"] {
      position: absolute;
      opacity: 0;
    }

    .RadioGroup-item .RadioGroup-label {
      position: relative;
    }

    .RadioGroup-item .RadioGroup-label .text {
      display: inline-block;
      margin-left: 20px;
      user-select: none;
    }

    .RadioGroup-item .RadioGroup-label::before {
      position: absolute;
      top: -1px;
      content: "";
      display: inline-block;

      height: 14px;
      width: 14px;

      border-radius: 14px;
      border: 1px solid $black;
      background-color: $white;
    }

    .RadioGroup-item .RadioGroup-label::before {
    }

    .RadioGroup-item .RadioGroup-label::after {
      position: absolute;
      left: 6px;
      top: 5px;
      content: "";
      display: inline-block;
      height: 4px;
      width: 4px;
      border-radius: 4px;
      background-color: $white;
    }

    .RadioGroup-item input[type="radio"] + .RadioGroup-label::after {
      content: none;
    }

    .RadioGroup-item input[type="radio"]:focus + .RadioGroup-label .text {
      text-decoration: underline;
    }

    .RadioGroup-item input[type="radio"]:checked + .RadioGroup-label::after {
      content: "";
    }

    .RadioGroup-item input[type="radio"]:checked + .RadioGroup-label::before {
      background-color: $black;
    }
  }
}
.SuperHeroChart {
  display: grid;
  grid-template-areas:
    "header"
    "heading"
    "values"
    "chart";
  grid-gap: 1em;

  @media (min-width: 800px) {
    grid-template-areas:
      "header heading"
      "header values"
      "header chart";
    grid-template-columns: auto 1fr;
    grid-column-gap: 4vw;
  }

  @media (min-width: 1200px) {
    grid-template-columns: 357px 1fr;
  }

  .chart-header {
    grid-area: header;
  }
}

.SuperHeroChart--nocontrols {
  grid-template-areas:
    "values"
    "chart";

  @media (min-width: 800px) {
    grid-template-areas:
      "values"
      "chart";
    grid-template-columns: 1fr;
    grid-column-gap: 4vw;
  }
}

.SuperHeroChart-heading {
  grid-area: heading;
  font-family: var(--domaine);
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
}
.SuperHeroChart-chart {
  grid-area: chart;
}
.SuperHeroChart-values {
  grid-area: values;

  .category-header-values {
    display: grid;
    width: 100%;

    grid-template-columns: auto 1fr;
    grid-gap: 1em;

    @media (min-width: 1400px) {
      grid-template-columns: repeat(3, auto) 1fr;
      grid-gap: 2vw;
    }
  }
  .category-header-value-item {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .value {
      display: block;
      margin-left: 0;
      text-align: left;
      min-width: 15ch;
    }
  }
}
