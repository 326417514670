@import "~scss/imports";
.Embed {
  --pad: 0.5em;
  border: 1px solid #dadada;
  border-radius: var(--pad);
  overflow: hidden;

  display: flex;
  width: 100%;

  code {
    display: block;
    margin: 1em 0;
  }
}
