@import "~scss/imports";

.CalculatorTile {
  padding: 10px;
}

.CalculatorTile-inner {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.15);

  a {
    @extend %reset-link;
    &:last-of-type {
      margin-top: auto;
    }
  }

  .author {
    margin-top: 1em;
  }

  > svg {
    margin-top: auto;
  }
}

.CalculatorTile-info {
  text-align: right;
  padding: 5px 20px;
}

.CalculatorTile-debt {
  margin-top: 1em;
}

.CalculatorTile-chart-wrap {
  > svg {
    width: 100%;
    height: 100%;
  }
}

.new-calc-button,
.CalculatorTile .meta {
  padding: 20px 20px 0;
}

.CalculatorTile-ActionBar {
  @extend %ActionBar;
  @extend %font--label;

  display: flex;
  justify-content: center;
  width: 100%;
  transition: transform $transition-timing;

  .action-link,
  .action-button {
    width: 100%;
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}
