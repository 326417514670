$screen-xxs: 374px !default;
$screen-xs: 425px !default;
$screen-sm: 480px !default;
$screen-smmed: 575px !default;
$screen-md: 768px !default;
$screen-lg: 992px !default;
$screen-xl: 1024px !default;
$screen-xxl: 1340px !default;

$breaks: ("xxs", "xs", "sm", "md", "lg", "xl", "xxl");

$breakpoints: (
  "xxs": (
    min-width: $screen-xxs,
  ),
  "xs": (
    min-width: $screen-xs,
  ),
  "sm": (
    min-width: $screen-sm,
  ),
  "smmed": (
    min-width: $screen-smmed,
  ),
  "md": (
    min-width: $screen-md,
  ),
  "lg": (
    min-width: $screen-lg,
  ),
  "xl": (
    min-width: $screen-xl,
  ),
  "xxl": (
    min-width: $screen-xxl,
  ),
);

@mixin breakpoint($name) {
  @media screen and #{inspect(map-get($breakpoints, $name))} {
    @content;
  }
}

$breakpoints-max: (
  "xxs": (
    max-width: $screen-xxs,
  ),
  "xs": (
    max-width: $screen-xs,
  ),
  "sm": (
    max-width: $screen-sm,
  ),
  "md": (
    max-width: $screen-md,
  ),
  "lg": (
    max-width: $screen-lg,
  ),
  "xl": (
    max-width: $screen-xl,
  ),
  "xxl": (
    max-width: $screen-xxl,
  ),
);
@mixin breakmax($name) {
  @media screen and #{inspect(map-get($breakpoints-max, $name))} {
    @content;
  }
}

@mixin font-sizes($sizes) {
  @include breaks("font-size", $sizes);
}

@mixin paddings($paddingsizes, $mult: 1) {
  @include breaks("padding", $paddingsizes, $mult);
}

@mixin breaks($property, $sizes, $mult: 1) {
  #{$property}: #{$mult * map-get($sizes, "base")};

  @each $break in $breaks {
    @include breakpoint($break) {
      #{$property}: #{inspect($mult * map-get($sizes, $break))};
    }
  }
}

@each $break in $breaks {
  .display-on-#{$break} {
    display: none;
  }
  @include breakpoint($break) {
    .display-on-#{$break} {
      display: inherit;
    }
    td.display-on-#{$break},
    th.display-on-#{$break} {
      display: table-cell;
    }
  }
}

@mixin buttonPad {
  padding: 0.75rem 1rem;
}
