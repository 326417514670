@import "~scss/imports";

.Year {
  $width: 3.25rem;
  $height: 6rem;

  display: grid;
  grid-template-columns: auto auto;
  padding: 7px 0 0 0;
  position: relative;
  text-align: center;

  @include breakpoint("md") {
    padding: 0.75rem 0 0 0;
  }

  & + .Year {
    margin-left: 0.5rem;
  }

  &:hover {
    color: $blue-bright;
  }

  &.is-editing {
    &:hover {
      color: $black;
    }
    background: rgba($blue-bright, 0.15);
    padding-left: 10px;
    border-radius: 4px;
  }

  .bar {
    height: $height;
    width: $width;
    margin: auto;
    grid-column: 1;
    position: relative;
    overflow: hidden;
    background: #eaedf0;
    margin-top: 10px;
  }

  .bar-value {
    grid-column: 1;
  }

  .spending & {
    .bar {
      background-color: $spending-muted-color;
    }
    .bar-baseline {
      background-color: $spending-baseline-color;
    }
    .bar-current {
      background-color: $spending-baseline-color;
    }
    &.has-addition .bar-current {
      background-color: $spending-user-color;
    }
    .delta {
      color: $spending-user-color;
    }
  }
  .revenue & {
    .bar {
      background-color: $revenue-muted-color;
    }
    .bar-baseline {
      background-color: $revenue-baseline-color;
    }
    .bar-current {
      background-color: $revenue-baseline-color;
    }
    &.has-addition .bar-current {
      background-color: $revenue-user-color;
    }
    .delta {
      color: $revenue-user-color;
    }
  }
  .bar-baseline {
    left: 0;
    height: 1px;
    background-color: black;
    position: absolute;
    width: 100%;
  }
  .bar-current {
    bottom: 0;
    left: 0;
    position: absolute;
    width: $width;
  }

  .delta {
    line-height: 1;
    min-height: 1.25em;
    grid-column: 1;
  }

  .year {
    grid-column: 1 / 2;
  }

  .EditDollars {
    justify-content: center;
    display: flex;
    flex-direction: column;
    grid-column: 2;
    // height: $height;
    margin-left: 0.5rem;
    margin-right: 20px;
    // z-index: 1;
    // width: 160px;

    // label {
    // 	display: flex;
    // 	align-items: center;
    // 	border: 1px solid $gray;
    // 	padding: 0;
    // 	background-color: white;
    // }

    // input {
    // 	width: 100%;
    // 	//@include buttonPad;
    // 	//padding-right: 0;
    // }
    // .unit-label {
    // 	@include buttonPad;
    // 	padding-left: 0;
    // }
    .button {
      display: block;
      margin-top: 1em;
      width: 100%;
      border: 1px solid $blue-bright;
      background: transparent;
      color: $blue-bright;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }
  }
}
