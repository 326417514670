.ChartDatum {
  .category-header-value-item {
    width: auto;
    .value {
      flex-direction: column;
      line-height: 1.3;
      :nth-child(2) {
        font-size: 75%;
      }
    }
  }
}
