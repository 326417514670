@import "~scss/imports";

.questions {
  display: grid;
  grid-template-areas:
    "progress"
    "question"
    "status";

  @media (min-width: $embedBreakpoint) {
    grid-template-areas: "question status" "progress status";
    grid-template-columns: 1fr 300px;
    grid-template-rows: 1fr auto;
  }
  width: 100%;
}
.question {
  grid-area: question;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  > * {
    padding: 1rem;

    @media (min-width: 500px) {
      padding: calc(3.5 * var(--pad)) calc(4 * var(--pad));
    }
  }
}
.questionWrap {
  align-self: stretch;
  height: 100%;
}
.status {
  grid-area: status;
  display: flex;
  background-color: $light-gray;
  justify-content: center;
}
.progress {
  grid-area: progress;
}
