@import "~scss/imports";

.FullCarousel {
  width: 100%;
  height: calc(100vh - #{$navHeight});
  display: flex;
  flex-direction: column;
}

.FullCarousel-inner {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.FullCarousel-flickity {
  position: absolute;
  height: 100%;
  width: 100%;

  .flickity-viewport {
    height: 100% !important;
  }
  .flickity-page-dots {
    bottom: 10px;
  }

  .flickity-prev-next-button {
    display: none;

    @include breakpoint("md") {
      display: block;
    }
  }

  .flickity-prev-next-button.previous {
    left: 30px;
  }
  .flickity-prev-next-button.next {
    right: 30px;
  }
}

.FullCarousel-nav {
  display: flex;
}

.FullCarousel-nav-item {
  color: $white;
  @extend %genre-tab;
  width: 100%;
}

.FullCarousel-item {
  width: 100%;
  height: 100%;
}

.Tutorial {
  background-color: rgba(black, 0.75);
  display: flex;
  height: 100vh;
}
.Tutorial-inner {
  position: relative;
  height: 100%;
  margin: auto;
  max-width: 800px;
  max-height: 800px;

  > figure {
    position: absolute;
    padding: 20px;
  }
}

.Tutorial-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 75px 5px;

  @include breakpoint("sm") {
    padding: 75px 15px;
  }

  @include breakpoint("md") {
    padding: 75px 100px;
  }

  video {
    display: block;
    //border: 1px solid black;
  }
}

.TutorialSlide--text {
  justify-content: center;
}

.Tutorial-slide-copy {
  overflow-y: auto;
}

.Edit .Tutorial {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
