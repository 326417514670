@import "~scss/mixins";
@import "~scss/variables";
@import "~scss/colorVars";
@import "~scss/spacing";
@import "~scss/type";

%faded {
  opacity: 0.6;
}
%reset-link {
  text-decoration: none;
  color: inherit;
}
%reset-button {
  background-color: transparent;
  border: 0;
  padding: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
}
%nav-pad {
  padding-top: 0;
}
%cta--large {
  display: inline-block;
  padding: 25px 15px;
  border-width: 2px;
}
%button {
  background-color: inherit;
  border-width: 1px;
  border-style: solid;
  border-color: $black;
  @extend %font--label;
  font-family: $ff-secondary;
  @include buttonPad;
  min-width: 8em;
  justify-content: center;

  &:hover,
  &:focus {
    background-color: $black;
    color: $white;
    outline: 0;
  }
}
%tab {
  border-color: unset;
  border-width: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @extend %font--label--alt;

  > svg {
    width: 15px;
    height: auto;
  }

  > svg,
  .change-count {
    margin-bottom: 5px;
  }
}
%genre-tab {
  width: 100%;
  @extend %tab;
  text-decoration: none;

  .label {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  color: $white;
  background-color: $white;
  position: relative;

  &:focus {
    outline: 0;
  }

  &.spending {
    background-color: $spending-baseline-color;
  }

  &.revenue {
    background-color: $revenue-baseline-color;
  }

  &.economy {
    background-color: $teal;
  }

  &.meta {
    background-color: $user-color;
  }

  &:focus,
  &:hover {
    &:not(.active):not(.react-tabs__tab--selected).spending {
      background-color: darken($spending-baseline-color, 20%);
    }

    &:not(.active):not(.react-tabs__tab--selected).revenue {
      background-color: darken($revenue-baseline-color, 20%);
    }

    &:not(.active):not(.react-tabs__tab--selected).economy {
      background-color: darken($teal, 20%);
    }

    &:not(.active):not(.react-tabs__tab--selected).meta {
      background-color: darken($user-color, 20%);
    }
  }

  &.active,
  &.react-tabs__tab--selected {
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      top: 100%;
    }
    &.spending:after {
      border-bottom: $tab-border-width solid $spending-baseline-color;
    }

    &.revenue:after {
      border-bottom: $tab-border-width solid $revenue-baseline-color;
    }

    &.economy:after {
      border-bottom: $tab-border-width solid $teal;
    }

    &.meta:after {
      border-bottom: $tab-border-width solid $user-color;
    }
  }
}
%ActionBar {
  background-color: $trueblack;
  color: $white;
  display: flex;
  padding: 0.25rem;

  &.page {
    bottom: 0;
    justify-content: flex-end;
    position: fixed;
    right: 0;
    z-index: 4;
  }

  &.cta {
    background-color: $forest;
    width: 100%;

    .action-link {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    &:hover svg {
      animation: floatArrow 2s linear infinite;
    }

    svg {
      margin-left: 1em;
    }
  }
}
@keyframes floatArrow {
  $distance: 20%;
  25% {
    transform: translateX($distance);
  }
  75% {
    transform: translateX(-$distance);
  }
}
