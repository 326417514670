@import "~scss/imports";
@import "~react-vis/dist/style";

.TaxCalc-inner {
  display: grid;
  grid-template-areas:
    "controls"
    "results"
    "chart";
  grid-gap: 1em;

  @media (min-width: 1000px) {
    grid-template-areas: "controls chart";
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-gap: 4vw;
  }
  margin-top: 50px;
}
.TaxCalc-description {
  margin-top: 1em;
  @extend %report-body;
  max-width: 40em;
}
.TaxCalc-controls {
  grid-area: controls;
  font-family: var(--basis);
  font-size: 16px;
  justify-self: start;

  border: 1px solid #ededed;
  display: grid;
  align-self: start;

  @media (min-width: 300px) {
    grid-template-columns: auto 1fr;
  }

  @media (min-width: 400px) {
    grid-template-columns: auto auto 1fr;
    grid-gap: 2em;
  }

  @media (min-width: 1000px) {
    grid-template-columns: 1fr;
    grid-gap: 0em;
  }
}
.TaxCalc-numberinput {
  max-width: 6em;
  display: flex;
  align-items: center;
  input {
    font-family: var(--mono);
    font-size: 100%;
    width: 100%;
    margin-left: 0.125em;
    border-width: 1px;
    border-style: solid;
    border-color: #dedede;
  }
}
.TaxCalc-radio {
  > * + * {
    margin-top: 0.25em;
  }
  label {
    display: flex;
    align-items: center;
  }
  input[type="radio"] {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
    margin-right: 0.25rem;

    &:checked + .TaxCalc-radio-label:before {
      background-color: #000;
    }
  }
}
.TaxCalc-radio-label {
  display: flex;
  align-items: center;
}
.TaxCalc-radio-label:before {
  content: "";
  display: inline-block;
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  border: 1px solid;
  margin-right: 0.5em;
}

.TaxCalc-controls-heading {
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
  font-family: var(--domaine);
  font-weight: bold;
  font-size: 18px;
}
.TaxCalc-controls-item {
  display: block;
  padding: 1em;
}
.TaxCalc-controls-result {
  padding: 1em;
}
.TaxCalc-controls-item-label {
  margin-bottom: 0.5em;
}
.TaxCalc table {
  width: 100%;
  margin-top: 1em;
  td,
  th {
    padding: 0.5em;
  }
}
.TaxCalc-value {
  font-family: var(--mono);
}
.TaxCalc-chart {
  grid-area: chart;
  height: 100%;
  display: grid;
  grid-template-areas:
    "data"
    "visual"
    "key";

  @media (min-width: 400px) {
    grid-template-areas:
      "data key"
      "visual visual";
    align-items: end;
    grid-template-columns: 1fr auto;
    grid-row-gap: 1em;
  }

  @media (min-width: 1000px) {
    padding-top: calc(1px + 1em);
    grid-template-rows: auto 1fr;
    grid-column-gap: 2em;
    grid-row-gap: 1em;
    grid-template-areas:
      "data key"
      "visual visual";
  }
}
.TaxCalc-chart-data {
  grid-area: data;
}
.TaxCalc-chart-visual {
  grid-area: visual;
}
.TaxCalc-chart-key {
  grid-area: key;
  max-width: 20em;
}
.category-header-values-label {
  font-family: var(--domaine);
  font-weight: bold;
  text-align: left;
  font-size: 18px;
}

.TaxCalc-results {
  grid-area: results;
  max-width: 9em;

  > * + * {
    margin-top: 1em;
  }
}

.TaxCalc-results-key {
  margin-top: 1em;
}
