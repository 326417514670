@import "~scss/imports";

.Report-controls {
  grid-area: controls;
  height: 4rem;

  position: sticky;
  top: 0;
  z-index: 3;

  @include breakpoint($editMobileBreakpoint) {
    flex-direction: column;
    height: 100vh;
  }
}
.Report-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .DisplayYearSlider.vertical {
    max-height: 300px;
  }
}
.Report-control {
  width: auto;
  height: 100%;

  @include breakpoint($editMobileBreakpoint) {
    flex-direction: column;
    .button-inline + .button-inline {
      margin-top: 4vh;
      margin-left: 0;
    }
  }
}

.Report-control {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 0.5rem;

  @include breakpoint($editMobileBreakpoint) {
    padding: 0.5rem 0;
  }
}

.Report-control-buttons {
  display: flex;
  align-items: stretch;

  .round-button .button-circle {
    width: 25px;
    height: 25px;
  }

  .button-inline {
    @extend %page-pad;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    &:hover {
      background-color: $dark-gray;
    }

    & + .button-inline {
      margin-left: 1em;
    }

    &.download {
      display: none;
    }
  }

  @include breakpoint($editMobileBreakpoint) {
    display: flex;
    flex-direction: column;

    .button-inline {
      & + .button-inline {
        margin-left: 0;
      }
    }

    .round-button .button-circle {
      width: 50px;
      height: 50px;
    }
  }
}
