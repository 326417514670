@import "~scss/imports";

.element {
}

.heading {
  @extend %embed-h2;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 500px) {
    margin-bottom: 1.5rem;
  }
}

.detail {
  padding: 1rem;
}
.graphWrap {
  position: relative;
  padding-bottom: 1em;
}
.graph,
.debt {
}
.debt .graphLabel {
  margin-left: 1rem;
}
.graph {
  display: grid;
  grid-template-columns: 6em 1fr;

  svg {
    display: block;
  }

  @media (min-width: 600px) {
    display: block;
  }
}
.graphLabel {
  @extend %report-label;
}
.graphIndicator {
  @extend %report-label;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 2px);
  font-family: var(--mono);
}
