@import "~scss/imports";

.AccountDetails {
  @include breakpoint("lg") {
    display: flex;
    min-height: 100vh;

    .Profile {
      min-width: 300px;
    }
    .Calculators {
      width: 100%;
    }
  }

  .nav-pad--large {
    padding-top: $navHeight;
    @include breakpoint("md") {
      padding-top: $navHeight * 4;
    }
  }

  .heading {
    margin-bottom: 1em;
  }
}

.Profile {
  background-color: $white;

  .link-button {
    margin-top: 2em;
  }
}

.Profile-item {
  display: block;

  svg {
    margin-right: 1rem;
  }
  &:not(:first-child) {
    //margin-top: 1em;
  }
}

.Calculators {
  background-color: $bg-alt;
}

.Login {
  &.loading {
    opacity: 0.5;
    pointer-events: none;
  }
}

.LoginForm {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}
