@import "~scss/imports";

.Sidebar {
  .Projections,
  .UserChanges {
    @extend %page-pad;
  }
}

.Sidebar .Projections {
  padding-top: 0;
}

.Budget {
  @include breakpoint($editMobileBreakpoint) {
    display: grid;
    grid-template-areas:
      "sidebar tabs"
      "sidebar editor";
    grid-template-columns: minmax(280px, 28%) auto;
    grid-template-rows: auto 1fr;
  }
}

.Projections {
  .HeaderChart-block-wrap {
    display: grid;

    @include breakpoint("sm") {
      grid-template-columns: 1fr;
    }
  }
}

.Budget {
  .genre-tabs {
    grid-area: tabs;
    position: sticky;
    top: $genreTabHeight;
    z-index: 2;

    @include breakpoint("md") {
      top: 0;
    }
  }
  .genre-editor {
    grid-area: editor;
  }
  .Sidebar {
    grid-area: sidebar;
    z-index: 3;
  }

  .Editor {
    z-index: 1;
  }
}

.display-year-wrap {
  align-items: center;
  display: flex;

  .display-year-value {
    margin-right: 0.5em;
  }
}

.FigureSet {
  width: 100%;
}

.FigureSet--column {
  table-layout: fixed;

  td {
    text-align: center;
    padding: 0.25em 1em;
    min-width: 5em;
  }
}

.FigureSet--table {
  td {
    white-space: nowrap;
  }
}

.HeaderChart-block-year {
  display: flex;

  @include breakpoint("sm") {
    flex-direction: column;
  }
}
.BudgetSnapshot {
  @include breakpoint("sm") {
    width: 100%;
  }
}
.ValueSet {
  width: 100%;

  & + .ValueSet {
    margin-top: 1em;
  }
}

.Value {
  display: grid;

  grid-template-areas:
    "label figure-delta"
    ". ."
    "figure-baseline bar-baseline"
    "figure-user bar-user"
    "figure-user-label .";
  grid-template-rows: auto 5px auto auto;
  grid-column-gap: 5px;
  grid-template-columns: minmax(6em, auto) 1fr;

  .Value-label {
    grid-area: label;
  }
  .Value-figure-user-label {
    grid-area: figure-user-label;
  }
  .Value-figure.delta {
    grid-area: figure-delta;
  }
  .Value-figure.baseline {
    grid-area: figure-baseline;
  }
  .Value-bar.baseline {
    grid-area: bar-baseline;
  }
  .Value-figure.user {
    grid-area: figure-user;
  }
  .Value-bar.user {
    grid-area: bar-user;
  }

  & + .Value {
    margin-top: 0.5em;
  }
}

.Value--large {
  grid-template-columns: minmax(7.5em, auto) 1fr;
}

.Value-figure {
  align-items: center;
  min-width: 6em;
  justify-content: space-between;
  display: flex;
}

.Value-figure .label {
  margin-right: 5px;
}

.Value-figure.delta {
  text-align: right;
}

.Value-bar {
  display: block;
  width: 100%;
}

//User Changes
.QuickChanges {
}

.QuickChange {
  & + .QuickChange {
    margin-top: 1em;
  }
}

.QuickChange-section {
  margin-top: 0.5em;
  padding-left: 0.5em;
}

.QuickChange-item {
  margin-top: 0.25em;
  padding-left: 0.5em;
}

.QuickChange-section,
.QuickChange-item {
  display: flex;
  width: 100%;

  .reset-button {
    box-sizing: content-box;
    width: 15px;
    flex-shrink: 0;
    padding-left: 5px;
    margin-left: auto;
  }
}

.QuickChange-section-column {
  flex-direction: column;
}

.QuickChange-category--link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

// .genre-editor,
.CategoryDetail {
  min-height: calc(100vh - #{$tabHeight});
}

.Group {
  padding-bottom: 1rem;
  @extend %page-pad;
  @include breaks("margin-bottom", $sizes-pagepad-large);

  .category-wrap {
    @include breaks("padding-top", $sizes-pagepad);
    @include breaks("padding-bottom", $sizes-pagepad);
  }
}

.Group-header {
  $sizes: (
    "base": 14px,
    "xxs": 14px,
    "xs": 14px,
    "sm": 16px,
    "md": 24px,
    "lg": 36px,
    "xl": 36px,
    "xxl": 36px,
  );
  padding-left: 0;
  @include breaks("padding-top", $sizes);
  @include breaks("padding-bottom", $sizes);

  .genre-settings {
    width: 100%;
  }

  .toggle {
    .fill {
      fill: $white;
    }
    .stroke {
      stroke: $white;
    }
  }
}

.BudgetChange td {
  padding-left: 3em;
}

//subcats
.category-grouping .category-header {
  .spending & {
    .fill {
      fill: $spending-baseline-color;
    }
    .stroke {
      stroke: $spending-baseline-color;
    }
  }
  .revenue & {
    .fill {
      fill: $revenue-baseline-color;
    }
    .stroke {
      stroke: $revenue-baseline-color;
    }
  }
}

//Categories
.category-wrap {
  border-top: 1px solid $gray;
  border-left: 1px solid $gray;
  border-right: 1px solid $gray;
  :last-child > & {
    border-bottom: 1px solid $gray;
  }
}

.subcategories {
  display: flex;
  flex-wrap: wrap;
  max-width: 60em;
  line-height: 1.7em;
  margin-top: 10px;
}

@include breakpoint("md") {
  .subcategory-wrap {
    display: flex;

    aside {
      flex-shrink: 0;
      line-height: 1.7;
    }
  }
  .subcategories {
    margin-top: 0;
  }
}

.subcategory-bars {
  overflow: visible !important;

  rect {
    &:hover {
      cursor: pointer;
    }
  }
}

.genre-editor {
  .category-header {
    .heading-wrap {
      padding-right: 1em;
    }

    .settings-wrap {
      margin-top: 0.5em;
      margin-left: auto;
    }

    //@include breakpoint('md') {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .settings-wrap {
      margin-top: 0;
    }
    //}

    .slide-toggle {
      display: flex;
    }

    .slide-toggle-button-user {
      transition: margin-left $transition-timing;
    }
    .slide-toggle-content.is-open + .slide-toggle-button-user {
      margin-left: 5px;
    }

    .heading {
      line-height: 1.5;
    }
  }
}

.slide-toggle-button-user {
  border: 0;
  padding: 0.75em 1.25em;
  border-radius: $ui-border-radius;
}

.category-header-description {
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
  max-width: 40em;
}

.category-header-values {
  grid-area: values;
  text-align: right;

  .label {
    margin-right: 1em;
  }
  .value {
    display: flex;
  }

  .chart-tooltip & {
    width: auto;
  }
}

.category-header-value-item {
  width: 8em;

  align-items: center;
  justify-content: space-between;
  display: flex;

  .value {
    margin-left: 0.5em;
  }
}

.Category,
.EconSetting {
  padding-left: 1rem;
}

.Category {
  display: grid;

  grid-template-areas:
    "heading heading"
    "values bars"
    "subcats subcats";
  @include breakpoint("md") {
    grid-template-areas:
      "heading heading"
      "values bars"
      ". subcats";
  }

  grid-template-columns: auto 1fr;
  @include breaks("grid-row-gap", $sizes-pagepad);
  @include breaks("grid-column-gap", $sizes-pagepad);

  .category-header {
    grid-area: heading;
  }

  .SubcategoryBars {
    grid-area: bars;
    display: flex;
    align-items: center;
  }

  .subcategories-label {
    grid-area: subcatlabel;
  }

  .subcategories {
    grid-area: subcats;
  }
}
.Subcategory {
  color: inherit;
  margin-right: 0.25em;

  &:hover {
  }
}

svg.no-data {
  background-color: $bg-empty;
}
svg.no-data g {
  display: none;
}

.category-value {
  @extend %font--number;
  font-size: 0.2em;

  &.centered {
    alignment-baseline: middle;
    text-anchor: middle;
  }
  &.edge {
    text-anchor: start;
    dominant-baseline: central;
  }
  .baseline & {
    fill: $text-over-shape-color;
  }
}

//in econ..chart + text
.category-description {
  display: grid;

  max-width: 40em;
  margin: 0.5em 0;

  &.category-description--has-copy {
    @include breakpoint("sm") {
      grid-template-columns: minmax(100px, 50%) auto;

      .EconTrendChart {
        display: block;
        margin-right: 2em;
        margin-top: 0.5em;
      }
    }
  }
}
