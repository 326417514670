//vars
@import "imports";
@import "fonts";
//global
@import "reset";
@import "ui";

.container-pad {
  padding: 0 4rem;
}
.page-pad {
  @extend %page-pad;
}

.page-pad--large {
  @extend %page-pad--large;
}
.outer-container {
  @include breaks("padding-left", $sizes-outercontainer);
  @include breaks("padding-right", $sizes-outercontainer);
}

.shadow {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.125);
}
.shadow-y {
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
}
//_components
@import "_components/CategoryDetail";
@import "_components/Policy";
@import "_components/Lightswitch";
@import "_components/Year";
@import "_components/Slider";
@import "_components/Econ";
@import "_components/DefaultEdit";
@import "_components/Budget";
@import "_components/GrowthRateSettings";
@import "_components/LoadCalculatorTile";
@import "_components/Account";
@import "_components/EditForm";
@import "_components/ActionBar";
@import "_components/ViewBudget";
@import "_components/Home";
@import "_components/HamburgerMenu";
@import "_components/FeaturedCalculators";
@import "_components/Sidebar";
@import "_components/FullCarousel";
@import "_components/ShareDialog";

@import "charts";

body {
  color: $black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
}

a {
  color: inherit;
}

@keyframes bounce-scroll-indicator {
  0%,
  10%,
  25%,
  40%,
  100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-15px);
  }
  30% {
    transform: translateY(-5px);
  }
}

.ScrollDown {
  animation-name: bounce-scroll-indicator;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}
.CreateButton {
  flex-shrink: 0;
}
.PlayVideo {
  align-items: center;
  background-color: transparent;
  border: 0;
  padding-right: 15px;
  @extend %button;
  display: inline-flex;

  margin-left: 1em;
  margin-top: 2em;

  &:hover,
  &:focus {
    background-color: inherit;
    color: inherit;
  }
}
.PlayIcon {
  //width: 45px;
  //height: 70px;
  margin-right: 1.5em;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-left-width: 1em;
    border-top-width: 0.5em;
    border-bottom-width: 0.5em;
    border-right-width: 0;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-style: solid;
  }
}

$VideoModal-width: 80vw;
$VideoModal-height: $VideoModal-width * 0.5625;
.VideoModal {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;

  > div {
    width: $VideoModal-width;
    height: 0;
    padding-bottom: $VideoModal-height; //width* 56.25%
    position: relative;

    > iframe {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
}
.VideoModal-wrap {
  width: 100%;
}
.VideoModal-close {
  color: white;
  position: fixed;
  left: calc(#{$VideoModal-width} + 50% - #{$VideoModal-width/2} + 10px);
  top: calc(50% - #{$VideoModal-height/2});
  z-index: 1000;

  &:after,
  &:before {
    background-color: white;
    content: "";
    display: block;
    width: 2px;
    height: 18px;
    position: absolute;
    transform-origin: center;
  }
  &:after {
    transform: rotate(45deg);
  }
  &:before {
    transform: rotate(-45deg);
  }
}

.anchor {
  position: absolute;
  text-indent: -100vw;
}

.svg-fullsize {
  display: block;
  height: 100%;
  width: 100%;
}

.detail-section {
  // margin-top: 20px;
  // @include breakpoint('md') {
  // 	margin-top: 3rem;
  // }

  &:last-child {
    padding-bottom: 20px;
    @include breakpoint("md") {
      padding-bottom: 3rem;
    }
  }
}

.react-tabs__tab-list {
  display: flex;
  justify-content: space-around;
}
//.react-tabs__tab {
//width: 100%;
//}

.ReactModal__Overlay {
  z-index: 101;
}

//site styles

.settings {
  display: flex;
  flex-wrap: wrap;
}
.settings label {
  display: block;
  padding: 0.5em;
}
.settings select {
  display: block;
}

//spending-rev tabs
.genre-tabs {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  //border-bottom: $tab-border-width solid transparent;

  //save button
  //todo refactor
  .button {
    border: 0;
    width: 25%;
    min-width: auto;
    padding: 0;
  }
  .react-router-tabs {
    display: flex;
    width: 100%;
  }

  @include breakpoint("lg") {
    .over-sidebar {
      min-width: 300px;
    }
  }

  .genre-tab {
    min-height: $genreTabHeight;
    width: 100%;
  }
}

.genre-tab {
  @extend %genre-tab;
}

.nav-pad {
  padding-top: 0;
}

.nav-pad--large {
  padding-top: 0;
}

.Nav {
  align-items: center;
  background-color: $trueblack;
  color: $white;
  display: flex;
  height: $navHeight;
  padding: 0 15px 0 20px;

  .home {
    align-items: center;
    display: flex;
  }

  svg {
    fill: $white;
  }

  .hamburger-btn {
    margin-right: 20px;
  }

  .nav-right {
    margin-left: auto;
    display: flex;
    align-items: center;

    .icon--link {
      align-items: center;
      display: flex;
      text-decoration: none;
      color: inherit;

      > svg,
      .icon {
        margin-right: 0.5rem;
      }
    }

    .divider {
      display: inline-block;
      width: 1px;
      height: 26px;
      background: rgba(255, 255, 255, 0.5);
      margin: 0 35px;
    }

    .fix-btn {
      margin-right: 30px;
    }

    .is-desktop-only {
      display: none;
      @include breakpoint("md") {
        display: block;
      }
    }
  }

  .nav-right-item + .nav-right-item {
    margin-left: 2rem;
  }

  a,
  button {
    transition: opacity 100ms ease-out;
  }

  a:hover,
  button:hover {
    opacity: 0.6;
  }

  a.icon--link {
    line-height: 0;
  }
}

.Nav-item-helper-text {
  display: none;

  @include breakpoint("md") {
    display: inline;
  }
}

.Nav-link {
  color: inherit;
  text-decoration: none;
}
.Nav-links {
  align-items: center;
  display: none;
  margin-left: auto;
}
@include breakpoint("md") {
  .Nav-links {
    display: flex;
  }
}
.Nav-links a {
  margin-right: 2em;
}

.InfoIcon {
  align-items: center;
  display: flex;
  border-radius: 50%;
  border: 1px solid;
  flex-shrink: 0;
  justify-content: center;
  padding: 8px;
  width: 1em;
  height: 1em;
}

.Login {
  align-items: center;
  display: flex;
}

.BudgetTable {
  td {
    border: 1px solid gray;
    padding: 0.25em;

    &:first-child {
      background-color: white;
      position: sticky;
      left: 0;
      font-weight: bold;
    }
  }
  tr {
    &:first-child td {
      background-color: white;
      font-weight: bold;
      position: sticky;
      top: #{$navHeight - 1};
      z-index: 1;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.125);
    }
  }
}

@import "colors";
