@import "~scss/imports";

.container {
  padding: 1rem;
  @media (min-width: 500px) {
    padding: calc(3.5 * var(--pad)) calc(4 * var(--pad));
  }
  display: grid;
  grid-template-areas:
    "image"
    "heading"
    "body"
    "link";

  @media (min-width: #{$embedBreakpoint}) {
    grid-template-areas:
      "heading image"
      "body image"
      "link image";
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 4rem;
  }
}
.heading {
  grid-area: heading;
  @extend %font--h1;
}
.body {
  grid-area: body;
  @extend %font--body;
}
.linkContainer {
  grid-area: link;
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  align-items: flex-start;

  * + * {
    margin-top: 1em;
  }

  @media (min-width: 350px) {
    flex-direction: row;
    align-items: center;

    * + * {
      margin-top: 0;
      margin-left: 2em;
    }
  }
}
.link {
  background-color: transparent;
  border-color: $black;
  color: inherit;
  justify-self: start;
  padding: 1.1rem 1.5rem;
  border: 2px solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  @extend %embed-button;

  &:focus {
    outline: 0;
  }

  :global(.next-arrow-icon) {
    margin-left: 1rem;
    display: flex;
    align-items: center;
    svg {
      stroke: currentColor;
    }
  }
}
.secondaryLink {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  @extend %embed-button;
}
.image {
  grid-area: image;
  width: 100%;
  align-self: center;
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  justify-items: center;
  justify-self: center;

  img {
    width: 100%;
    max-width: 200px;
  }
  figure {
    margin-top: 0;
    flex-shrink: 0;
    svg * {
      fill: currentColor;
    }
  }

  @media (min-width: #{$embedBreakpoint}) {
    max-width: 100%;
    grid-template-columns: 1fr;
    justify-items: flex-end;

    img {
      order: 2;
      max-width: 100%;
    }
    figure {
      order: 1;
    }
  }
}
.Embed-landing-image-logo .Embed-final {
  padding: var(--pad);
}
.Embed-landing-body-value {
  font-weight: bold;
}
