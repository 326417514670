@import "~scss/imports";

.EconSetting {
  .GrowthSlider {
    .rc-slider-handle {
      border-color: $black;
    }
    .rc-slider-rail {
      background-color: $black;
    }
    .growth-slider-tooltip {
      color: $black;
    }
  }
}
.EconSettingsPair {
  @include breakpoint("lg") {
    display: flex;
  }
}
