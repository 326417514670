@import "~scss/imports";

.ActionBar {
  @extend %ActionBar;
}

.action-link,
.action-button {
  align-items: center;
  color: $white;
  display: flex;
  justify-content: center;
  min-height: 4rem;
  padding: 0 1em;
  position: relative;

  @extend %font--label;

  .ActionBar:not(.cta) & {
    text-transform: uppercase;
  }

  &:not(:first-child):before {
    border-left: 1px solid $white;
    content: "";
    display: block;
    left: 0;
    height: 80%;
    top: 10%;
    position: absolute;
    width: 1px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.action-button {
  @extend %reset-button;
}
.action-link {
  color: inherit;
  text-decoration: none;
}

.action-pad {
  padding-bottom: 4.5rem;
}
