@import "~scss/imports";

.ShareDialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  input {
    width: auto;
    border: 1px solid $black;
    padding: 0.25em;
  }

  img {
    max-width: 225px;
  }
}
.ShareDialog-link {
  margin-top: 1em;
}

.ShareDialog-heading {
  font-family: $ff-secondary;
  font-size: 20px;
}

.ShareDialog-buttons {
  display: flex;
  align-items: center;
  margin-top: 2em;

  .ShareDialog-button + .ShareDialog-button {
    margin-left: 1em;
  }
}
.ShareDialog-button {
  display: inline-flex;
  align-items: center;
}

.SocialMediaShareButton {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 5px;

  svg {
    width: 25px;
    height: 25px;

    &,
    & * {
      fill: $trueblack;
    }
  }
}

.ShareDialog-button-message {
  position: absolute;
  top: 100%;
  left: 0;
  color: $black;
}

.ShareDialog-close-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 1em;
  right: 1em;

  svg {
    width: 3em;
  }
}
