.Accordion {
  img {
    transition: transform 0.3s;
  }
}

.Accordion-button {
  font: inherit;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  cursor: pointer;
  padding: unset;
  text-align: left;
  display: block;
  width: 100%;

  &:focus {
    outline: 0;
  }
}

.Accordion--open {
  img {
    transform: rotate(180deg);
  }
}
