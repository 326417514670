$ff-primary: "domaine-sans-regular";
$ff-secondary: "basis-grotesque-medium";
$ff-mono: "basis-grotesque-mono";

$editMobileBreakpoint: "md";
$transition-timing: 0.3s;
$navHeight: 60px;
$tabHeight: 80px;
$genreTabHeight: 50px;
$ui-border-radius: 8px;
$tab-border-width: 8px;

$embedBreakpoint: 700px;

$sizes-pagepad: (
  "base": 10px,
  "xxs": 10px,
  "xs": 12px,
  "sm": 14px,
  "md": 16px,
  "lg": 18px,
  "xl": 18px,
  "xxl": 18px,
);

$sizes-outercontainer: (
  "base": 10px,
  "xxs": 10px,
  "xs": 12px,
  "sm": 14px,
  "md": 18px,
  "lg": 36px,
  "xl": 36px,
  "xxl": 36px,
);
