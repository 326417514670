@import "~scss/imports";

.CalcHeader {
  grid-area: calc-header;
}
.CalcHeader-inner {
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;

  .CalcHeader-icon {
    display: none;
  }

  @include breakpoint("md") {
    display: grid;
    grid-template-areas:
      "icon text"
      "toggle toggle";
    grid-template-columns: auto 1fr;
    grid-column-gap: 32px;
    padding: 32px 32px 16px;

    .CalcHeader-icon {
      display: block;
    }
  }

  img {
    width: 100%;
    max-width: 158px;
  }
}
.CalcHeader-text-heading {
  @extend %report-header-heading;
}
.CalcHeader-text-body {
  @extend %report-bodylarge;
}
.CalcHeader-icon {
  grid-area: icon;
}
.CalcHeader-text {
  grid-area: text;
  max-width: 35em;
  padding-top: 1.5em;
  p:first-child {
    margin-top: 0;
  }
}

.CalcHeader-toggle {
  grid-area: toggle;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-self: flex-end;

  border: 2px solid transparent;
  background-color: transparent;
  border-radius: 3px;
  cursor: pointer;
  padding: 1em 2em;

  @extend %report-label;

  &.active img {
    transform: rotate(180deg);
  }

  &:focus {
    border-color: #fff;
    outline: 0;
  }

  img {
    width: 16px;
    margin-left: 0.75em;
    transition: transform 0.3s;
  }
}
