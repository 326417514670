@import "~scss/imports";

.Report-footer {
  position: relative;
  z-index: 3;

  > * {
    padding: 22px 1em;
  }

  @include breakpoint("md") {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .Report-footer-unsaved {
      grid-column: 1/-1;
    }
    .Report-footer-create {
      grid-column: 1/-1;
      display: flex;
      align-items: center;
      justify-content: center;

      .Report-footer-create-heading {
        margin-right: 6vw;
      }
      .Report-footer-create-link {
        margin-left: 3vw;
      }
    }
    .Report-footer-module {
      padding: 67px;
    }
  }
}
.Report-footer-unsaved {
  background-color: $white;
}
.Report-footer-create {
  display: grid;
  grid-gap: 1em;
}
.Report-footer-create-link {
  border: 2px solid;
  text-decoration: none;
  @extend %report-label;
  text-align: center;

  &:hover,
  &:focus {
    background-color: #000;
    border-color: transparent;
  }
}

.Report-footer-module {
  display: grid;
  grid-gap: 1rem;
}

.Report-footer-module-link {
  @extend %report-label;
  text-decoration: none;
  display: flex;
  align-items: center;
  svg {
    stroke: currentColor;
    margin-left: 1em;
  }
}
